import React, { Component } from "react";
import "./styles/homeIndex.css";
import "bootstrap/dist/css/bootstrap.min.css";
import PackagesServices from "../services/packages.service"
import { ToastsStore } from "react-toasts";
import { Helmet } from "react-helmet";
import Cryptr from "cryptr";
import CryptoJS from "crypto-js";
import logoImage from "./images/thisheart_group.svg";
import heartBanner from "./images/heart-banner.svg";
import profilePhoto from "../pages/images/dp.png";
import { encrypt_url, decrypt_url, generateKey, encryptData, decryptData } from '../helpers/encrypt-url';
import { userService } from "../services/user.service";

class SelectPackagePlan extends Component {

  constructor(props) {
    super(props);
    const user = localStorage.getItem("user");
    let userObj = JSON.parse(user);
    this.state = {
      userObj: userObj,
      loading: false,
      selected_plan: "",
      packageList: '',
      starter_pkg: '',
      access_pkg: '',
      family_pkg: '',
      prev_selected_package: 'access'
    }
  }

  componentDidMount() {

    localStorage.setItem("select_package", "yes");
    this.getPackages()
  }

  getPackages = () => {
    PackagesServices.getPackages(this).then(
      (response) => {

        if (response.data.status === "success") {
          const package_list = response.data.data
          const starter_pkg = package_list.filter(pkg => (pkg.package.toLowerCase() === "STARTER".toLowerCase()))
          const access_pkg = package_list.filter(pkg => (pkg.package.toLowerCase() === "ACCESS".toLowerCase()))
          const family_pkg = package_list.filter(pkg => (pkg.package.toLowerCase() === "FAMILY".toLowerCase()))
          this.setState({
            packageList: response.data.data,
            starter_pkg: starter_pkg,
            access_pkg: access_pkg,
            family_pkg: family_pkg
          });

        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getPackageByUser = () => {
    if (localStorage.getItem('user_type') === "bn" || localStorage.getItem('user_type') === "contributor") {
      return false;
    }
    PackagesServices.getPackageByUser(this).then(
      (response) => {
        if (response.data.status === "success") {
          const package_info = response.data.data;
          this.setState({
            userList: {
              ...this.state.userList,
              subPlan: package_info.id,
              selPackageTitle: package_info.package,
            },
            package_info: response.data,
          });

        }
      },
      (error) => {
        console.log(error);
        ToastsStore.error(
          "Sorry, package info error, " + error.response.data.message
        );
      }
    );
  };


  selectPackage = (package_id, e) => {

    e.preventDefault();
    if (package_id) {
      this.setState({
        selected_plan: package_id,
        loadingPkg: true
      })
    }
    let data = {
      sub_plan: package_id,
    };

    PackagesServices.savePackageInfo(this, data).then(
      (response) => {
        if (response.data.status === "success") {
          const reg_email = localStorage.getItem("reg_email");
          const encryptedString = encrypt_url(reg_email);
          window.location.href = "/RegisterEmailConfirmation/" + encodeURIComponent(encryptedString);
          return true;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }


  selectedPackage = () => {
    return (
      <div className="banner-pri-love-01">
        <img src={heartBanner}></img>
      </div>
    )
  }

  handleBackClick = async () => {
    try {
      const result = await userService.deleteUser(this.state.userObj.data.data.id);
      if (result.data.status === 'success') {
        console.log("User and email deleted successfully");
        this.props.history.goBack();
      } else {
        console.error("Failed to delete user and email");
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  render() {
    return (
      <div id="landing-home" className="container-fluid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Select Package</title>
        </Helmet>
        <div id="header" className="fixed-top pb-3 container-fluid">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3  "  >
                <img
                  src={logoImage}
                  onClick={this.redirectImgLink}
                  alt="logo"
                  className="img-responsive ml-3"
                  style={{ cursor: "pointer" }}
                ></img>
              </div>
              <div className="col-md-7"></div>
              <div className="col-md-2 text-right">
                <img src={profilePhoto} style={{ height: '45px', width: '45px' }} className="mr-3"></img>
              </div>

            </div>{/**** end top row ****/}
          </div>
        </div>

        <div className="row mt-3 pt-3" style={{ height: '90vh' }}>

          <div className="row mt-5 pt-2 text-end ml-3">
            <button type="button" className="btn add-btn-mem ml-3" onClick={this.handleBackClick}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
              </svg> Go Back
            </button>
          </div>

          <div className="col-md-12 text-center mt-0">
            <h3 className="text-muted mt-3"> Select a package to use for new account.</h3>
            <h2 className="text-dark">Let's keep the love going.</h2>
            <h6 className="text-muted">Pick a package to carry on.</h6>
          </div>

          {/* start pricing area  */}
          <div id="pricing" className="col-md-12 mt-0" >
            <div className="container" style={{ marginTop: '-15px' }}>
              <div className="price-title-head">
                {/* <h2 className="text-center">Start leaving things behind today</h2>
                <span>The first 30 days are free </span> */}
              </div>
              <div className="row mb-4">
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-4">
                  <div className={this.state.prev_selected_package === 'starter' ? 'pri_table_list border border-secondary' : 'pri_table_list no-border'}>
                    {this.selectedPackage()}

                    <h3>Starter</h3>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">9.99</span>
                      <span className="type">\mo</span>
                    </div>
                    <p className="title-desc">Test The Waters -Try Before Fully Committing</p>
                    <hr />

                    <ol>
                      <li>10 GB Data Storage</li>
                      <li>Store 10 accounts</li>
                      <li>Access to 2 Beneficiaries</li>
                      <li>Email User Support </li>
                    </ol>

                    <button type="submit" className="price-btn mt-3" onClick={this.selectPackage.bind(this, this.state.starter_pkg[0] && this.state.starter_pkg[0].id)}>
                      Select this plan
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-4">
                  <div className={this.state.prev_selected_package === 'access' ? 'pri_table_list border border-secondary' : 'pri_table_list no-border'}>
                    {this.selectedPackage()}

                    <h3>Access</h3>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">19.99</span>
                      <span className="type">\mo</span>
                    </div>

                    <p className="title-desc">All features and benefits of Access Package </p>
                    <hr />
                    <ol>
                      <li>Boundless Data Storage</li>
                      <li>Limitless Account Capacity</li>
                      <li>Unrestricted Beneficiary Access</li>
                      <li>Support via Email and Chat</li>
                    </ol>
                    <button type="submit" className="price-btn" onClick={this.selectPackage.bind(this, this.state.access_pkg[0] && this.state.access_pkg[0].id)}>
                      Select this plan
                    </button>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-4">
                  <div className={this.state.prev_selected_package === 'family' ? 'pri_table_list border border-secondary' : 'pri_table_list no-border'}>
                    {this.selectedPackage()}

                    <h3>Family</h3>
                    <div className="price">
                      <span className="currency">$</span>
                      <span className="value">39.99</span>
                      <span className="type">\mo</span>
                    </div>

                    <p className="title-desc">
                      An Account for you and 3 of your family members
                    </p>
                    <hr />
                    <ol>
                      <li>All of the Access Plan Features</li>
                      <li>For up to 3 of your family members.</li>
                      <li>That's 4 total accounts including yours</li>
                      <li>&nbsp;</li>
                    </ol>
                    <button type="submit" className="price-btn" onClick={this.selectPackage.bind(this, this.state.family_pkg[0] && this.state.family_pkg[0].id)}>
                      Select this plan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End pricing table area */}
        </div>
      </div>
    );
  }
}
export default SelectPackagePlan;
