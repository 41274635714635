import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/passwordmanage.css";
import {
  Button,
  Form,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Helmet } from "react-helmet";
import StepServices from "../services/steps.service";
import { FaKey } from "react-icons/fa";
import { encrypt_url } from "../helpers/encrypt-url";
import "font-awesome/css/font-awesome.min.css";


class OnBoardingPasswordManagement extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");

    this.state = {
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      modalMessage: false,
      encryptedString: encrypt_url(),
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  pageRedirectPrevious = () => {
    this.props.history.push("/onboardsocial");
  };

  pageRedirectNext = () => {
    let data = {
      step: "step-03",
      info: "password manager",
    };
    StepServices.setSteps(this, data).then(
      (response) => {
        console.log(response);
        this.props.history.push("/onboardlife_status");
      },
      (error) => {
        console.log(error);
      }
    );
    return false;
  };

  toggleModalMessage = () => {
    this.setState((prevState) => ({
      modalMessage: !prevState.modalMessage,
    }));
  };

  render() {
    return (
      <div className="password-container container-fluid">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding Password</title>
        </Helmet>

        <div className="row">
          <div className="container image-step-password">
            <div className="col-sm-8 col-md-11 col-lg-5 col-xs-6 text-pass">
              <h4>
                PASSWORD <br />
                MANAGEMENT
              </h4>
              <p>
                Making sure that the people who depend on you can later access
                the online systems that you use is pretty important. Let us help
                you centralize your passwords and get access to the right people
                at the right time.
              </p>
              <div className="setblock">
                <Form>
                  <Button className="set-it" onClick={this.toggleModalMessage}>
                    Set it up
                  </Button>
                </Form>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-1 col-md-1 col-xs-1"></div>
              <div className="col-sm-9 col-md-9 col-xs-9">
                <div className="prev-next-pass">
                  <Button style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500" }} onClick={this.pageRedirectPrevious} >
                    <i class="fa fa-solid fa-arrow-left mr-1"></i> Prev
                  </Button>
                  <Button className="ml-3" style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500" }} onClick={this.pageRedirectNext}>
                    Next <i class="fa fa-solid fa-arrow-right mr-1"></i>
                  </Button>
                </div>
              </div>
            </div>

            <div className="pass-skip">
              <p className="mbn-5">
                <Link
                  style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500", textDecoration: "none" }}
                  to={`/dashboard/${this.state.encryptedString}`}
                  className="btn btn-secondary"
                >
                  {localStorage.setItem("twofa", true)}
                  Exit
                </Link>
              </p>

            </div>
          </div>
        </div>

        <div id="image-res"></div>

        {/* /****************************** Message Custom Pop Up Start ****************************/}
        <div>
          <Modal
            isOpen={this.state.modalMessage}
            toggle={this.toggleModalMessage}
            className={this.props.className + ' modal-lg'}
          >
            <ModalHeader toggle={this.toggleModalMessage}>
              <FaKey /> &nbsp; Password Management
            </ModalHeader>

            <ModalBody className="form-popup">
              <Form>
                <p>
                  {" "}
                  Securing your passwords is essential for your data privacy
                  today. Securing your passwords in a single safe place is
                  helpful for later when the people you love will need to access
                  your accounts. <br /> These are some of the industry’s leading
                  password managers. If you haven’t already, we encourage you to
                  start storing your passwords with one of these.
                </p>
                <div style={{ marginTop: "15px" }}>
                  <Button
                    className="pass-linkbtn"
                    href="https://www.lastpass.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={this.toggleModalMessage}
                  >
                    LastPass
                  </Button>

                  <Button
                    className="pass-linkbtn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://1password.com/"
                    onClick={this.toggleModalMessage}
                  >
                    1password
                  </Button>

                  <Button
                    className="pass-linkbtn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://www.keepersecurity.com/"
                    onClick={this.toggleModalMessage}
                  >
                    Keeper
                  </Button>

                  <Button
                    className="pass-linkbtn"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://bitwarden.com/"
                    onClick={this.toggleModalMessage}
                  >
                    BitWarden
                  </Button>
                </div>

                <Button onClick={this.toggleModalMessage} className="btn-done">
                  Done
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
        {/* /****************************** Message Pop Up End ****************************/}

        {/* <Footer {...this.props} /> */}
      </div>
    );
  }
}
export default OnBoardingPasswordManagement;
