import React, { Component } from "react";
import { withRouter } from "react-router-dom"; // Ensure withRouter is imported
import "./styles/homeIndex.css";
import "./styles/memories.css";
import "./styles/featmemoriespage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logoLight from "../pages/images/thisheartweb/logolight.png";
import IndexFooter from "./IndexFooter";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import LeftSidebar from "../components/LeftSidebar";
import { FaCircle, FaPlay, FaMusic, FaPlus, FaMinus } from "react-icons/fa";
import demoAudioImg from "./images/audio_view2.png";
import deleteIcon from "./images/delete-icon.svg";
import apiService from "./../services/api.service";
import { memoriesService } from "../services/memories.service";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const apiUrl = apiService.apiUrl;

class MemoriesAudios extends Component {
  constructor(props) {
    super(props);

    this.toggleDeleteRecord = this.toggleDeleteRecord.bind(this);
    this.state = {
      recordLists: [],
      currentView: 'audios',
      userObj: JSON.parse(localStorage.getItem("user")),
      userType: localStorage.getItem("userType"),
      activeTab: "5",
      user_id: "",
      beneficiary_id: "",
      primary_user_id: "",
      currentPage: 1,
      audiosPerPage: 12,
      user_type: localStorage.getItem("user_type"),
    };
    this.handleBackClick = this.handleBackClick.bind(this); // Ensure binding of handleBackClick
  }

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    this.setState({
      user_id: this.state.userObj.data.user_id,
      beneficiary_id: "",
      primary_user_id: this.state.userObj.data.primary_user_id
    })
    console.log('1 user details:::', this.state.userObj.data.user_id,
      this.state.userObj.data,
      this.state.userObj.data.primary_user_id)

    const query = new URLSearchParams(this.props.location.search);
    const actions = query.get("actions");
    if (actions === "social") {
      this.setState({
        activeTab: "5",
      });
      this.toggleTab("5");
    }
    if (actions === "image") {
      this.setState({
        activeTab: "1",
      });
      this.toggleTab("1");
    }
    if (actions === "video") {
      this.setState({
        activeTab: "2",
      });
      this.toggleTab("2");
    }
    if (actions === "letters") {
      this.setState({
        activeTab: "4",
      });
      this.toggleTab("4");
    }
    if (actions === "record") {
      this.setState({
        activeTab: "5",
      });
      this.toggleTab("5");
    }

    memoriesService.getLetterData(userId, this);
    memoriesService.getAllMemoriesData(this);
  }

  toggleDeleteRecord(deleteID) {
    this.setState((prevState) => ({
      deleteRecord: !prevState.deleteRecord,
      deleteID: deleteID,
    }));
  }

  handleBackClick() {

    if (this.props.history.length > 1) {
      this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    } else {

      this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    }
  }
  deleteRecords() {
    const tempRecordID = this.state.deleteID;
    let successDel = memoriesService.deleteRecordData(tempRecordID);
    if (successDel) {
      const recordLists = this.state.recordLists.filter(
        (record) => record.id !== tempRecordID
      );
      this.setState({
        recordLists,
      });
      this.setState({
        deleteRecord: false,
      });
    } else {
      window.alert("Delete Failed");
    }
  }

  setViewToAudios = () => {
    this.setState({ currentView: 'audios' });
  };

  truncateText = (htmlContent, charLimit) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    const textContent = div.textContent || div.innerText || "";

    if (textContent.length > charLimit) {
      return `${textContent.slice(0, charLimit)}...`;
    } else {
      return textContent;
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  }

  renderPagination = (totalAudios, audiosPerPage) => {
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalAudios / audiosPerPage); i++) {
      pageNumbers.push(i);
    }

    return (
      <nav>
        <ul className="pagination">
          {pageNumbers.map(number => (
            <li key={number} className={`page-item ${this.state.currentPage === number ? 'active' : ''}`}>
              <button
                onClick={() => this.handlePageChange(number)}
                className="page-link"
                style={{
                  backgroundColor: this.state.currentPage === number ? '#f6da20' : 'white',
                  color: this.state.currentPage === number ? 'black' : 'black',
                  border: '1px solid #e2e2e2',
                  fontWeight: '600',
                  borderRadius: '5px',
                  padding: '10px 20px',
                  margin: '0 5px',
                  outline: 'none',
                  cursor: 'pointer',
                }}
              >
                {number}
              </button>
            </li>
          ))}
        </ul>
      </nav>
    );
  }

  renderAudiosWithPlaceholders = () => {
    const { recordLists, currentPage, audiosPerPage, user_type } = this.state;

    const validAudios = recordLists.filter(audio => /\.(mp3|wav|ogg|m4a)$/i.test(audio.filename));
    const reversedValidAudios = user_type === 'primary' ? validAudios : [...validAudios].reverse();
    const indexOfLastAudio = currentPage * audiosPerPage;
    const indexOfFirstAudio = indexOfLastAudio - audiosPerPage;
    const currentAudios = reversedValidAudios.slice(indexOfFirstAudio, indexOfLastAudio);
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { weekday: 'short', hour: '2-digit', minute: '2-digit', hour12: true };
      const formattedTime = date.toLocaleTimeString('en-US', options);
      const formattedDate = date.toLocaleDateString('en-GB');

      return (
        <div className="d-flex justify-content-between">
          <div>{formattedTime}</div>
          <div>{formattedDate}</div>
        </div>
      );
    };

    return (
      <div className="d-flex flex-wrap justify-content-start" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {currentAudios.length > 0 ? (
          currentAudios.map((audio, index) => (
            <div key={index} className="audio-card" onClick={() => this.setViewToAudios(audio)}>
              {(user_type === "primary" || audio.user_id == this.state.user_id) && (
                <button className="delete-button" onClick={this.toggleDeleteRecord.bind(this, audio.id)}>
                  <img src={deleteIcon} alt="deleteIcon" />
                </button>
              )}

              <div className="audio-thumbnail">
                <p className="audio-title" title={audio.title} style={{ textAlign: "center" }} dangerouslySetInnerHTML={{ __html: this.truncateText(audio.title, 10) }}></p>
                <audio src={audio.record_url} type="audio/mpeg, audio/*" controls style={{ width: '100%' }}>

                  Your browser does not support the audio element.
                </audio>
              </div>

              <div className="audio-date-time mt-2 w-100">
                {formatDate(audio.updated_at)}
              </div>
            </div>
          ))
        ) : (
          <div className="audio-card-placeholder" onClick={() => this.setViewToAudios()}>
            <div className="audio-thumbnail-placeholder">
              <img className="img-fluid" src={demoAudioImg} alt="demoAudioImg" />
            </div>
          </div>
        )}
      </div>
    );
  };

  render() {
    const { recordLists, audiosPerPage } = this.state;
    const totalAudios = recordLists.filter(audio => /\.(mp3|wav|ogg|m4a)$/i.test(audio.filename)).length;

    return (
      <div className="home-container" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Audios</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container-fluid mt-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <h1>Audios</h1>
                </div>
                <div className="col-6">
                  <div className="d-flex col-lg-11 justify-content-end align-items-center go-back-padding-audio" >
                    <button type="button" className="btn add-btn-mem mt-2 mr-3 shadow-sm" onClick={this.handleBackClick}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left mr-1" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                      </svg>
                      <span><span className="go-back-text-audio">Go</span> Back </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {this.renderAudiosWithPlaceholders()}
              {totalAudios > audiosPerPage && this.renderPagination(totalAudios, audiosPerPage)}
            </div>
          </div>
        </div>

        <div>
          <Modal
            isOpen={this.state.deleteRecord}
            toggle={this.toggleDeleteRecord}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteRecord}>
              Memories Record
            </ModalHeader>
            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              <p> Are you sure, want to Delete this item ? </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteRecords.bind(this)}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteRecord}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(MemoriesAudios);