import React, { Component } from "react";
import { Link } from "react-router-dom";
// import StepServices from "../services/steps.service";
import { medicalService } from "../services/medicalhistory.service";
import loaderImg from "./images/loader.gif";
import { ToastsStore, ToastsContainer } from "react-toasts";
import {
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Input,
} from "reactstrap";

// import Header from "../components/Header";
import "./styles/healthboarding.css";
import { Helmet } from "react-helmet";
import { encrypt_url } from "../helpers/encrypt-url";
import step6 from "./images/Step6.png";
import step6pd from "./images/Step6pd.png";


class OnBoardingHealth extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.state = {
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      selectedHealth: "",
      medicalList: [],
      errorHealth: "",
      loadingHealthData: false,
      encryptedString: encrypt_url(),
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  componentDidMount() {
    medicalService.getDiagnosisInfoAll(this).then(
      (response) => {
        if (response.data.status === "success") {
          this.setState({ medicalList: response.data.data });
        }
        console.log("medical list: ", response);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  handleChange = (e) => {
    this.setState({ selectedHealth: e.target.value });
    console.log(this.state.selectedHealth);
  };
  pageRedirectPrevious = () => {
    this.props.history.push("/onboardbenefi_select");
  };

  pageRedirectNext = () => {
    if (!this.state.selectedHealth) {
      this.setState({ errorHealth: "Please select a Health Issue." });
      //ToastsStore.warning("Sorry, Please select a Health Issue.");
      return false;
    }
    this.setState({ loadingHealthData: true });
    let data = {
      selectedHealth: this.state.selectedHealth,
      step: "step-06",
      info: "onboardhealth",
      member_type: "Me",
    };

    medicalService.saveHealthOnBoard(this, data).then(
      (response) => {
        if (response.data.status === "success") {
          this.setState({ loadingHealthData: true });
          localStorage.setItem("twofa", true);
          this.props.history.push(
            `/dashboard/${this.state.encryptedString}`
          );
        }
        ToastsStore.success("Medical information has been saved successfully!");
      },
      (error) => {
        console.log(error);
        ToastsStore.error("Sorry, Medical information hasn't been saved !");
      }
    );
    return false;
  };

  goToAddMoreHealth = (e) => {
    e.preventDefault();
    localStorage.setItem("twofa", true);
    this.props.history.push(`/medicalhistory/${this.state.encryptedString}`);
  };

  render() {
    return (
      <div className="container-fluid health-container mt-4">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding Health</title>
        </Helmet>

        <div className="row container m-auto">
          <div className="col-lg-6 col-md-2 pr-0">
            <img className="img-fluid d-none d-lg-block" src={step6} alt="benefi" />
            <img className="img-fluid d-md-block d-lg-none d-none" src={step6pd} alt="benefi" />
          </div>
          <div className="col-lg-6 col-12 col-md-10">
            <h4 className="d-block d-lg-none d-md-none">06</h4>

            <h4>
              KNOWN <br />
              HEALTH ISSUES?
            </h4>
            <p>
              Your medical history is important to the people you leave
              behind. Start to tell us about your medical history. We take
              your privacy very serious.
            </p>
            <Form className="health-form">
              <Row>
                <Col sm={8}>
                  <FormGroup>
                    <label style={{ color: "#ad2fdf" }}>
                      <p className="mb-0">Select Medical Issue</p>
                    </label>
                    <Input
                      type="select"
                      name="medicalType"
                      id="medicalType"
                      required
                      className="med-box"
                      onClick={this.handleChange}
                    >
                      <option value="" style={{ color: "#bebebe" }}>Select Medical Issue</option>
                      {this.state.medicalList.map((medical, index) => {
                        return (
                          <option key={index} value={medical.id}>
                            {medical.diagnosis_name}
                          </option>
                        );
                      })}
                    </Input>
                    {this.state.errorHealth && (
                      <div className="alert alert-warning">
                        {this.state.errorHealth}
                      </div>
                    )}
                  </FormGroup>

                  <div className="d-flex justify-content-between">
                    <div>
                      <Button className="btn-ben" style={{ padding: "6px 12px" }} onClick={this.pageRedirectNext}>
                        Save
                      </Button>
                    </div>
                    <div>
                      <Link
                        to={`/medicalhistory/${this.state.encryptedString}`}
                        className="btn btn-outline-secondary"
                        style={{ borderRadius: "7px" }}
                      >
                        Add more +
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Form>

            <div id="image-res">
              <div className="prev-next-health">
                <Row>
                  <Col sm="5">
                    {this.state.loadingHealthData && (
                      <img
                        className="loader-img"
                        alt="loaderImg"
                        src={loaderImg}
                      />
                    )}
                  </Col>
                </Row>
                <div className="prev-skip-health d-flex justify-content-between align-items-center">
                  <div>
                    <Button style={{ borderRadius: "7px", color: "#000", fontWeight: "500" }} onClick={this.pageRedirectPrevious}>
                    <i class="fa fa-solid fa-arrow-left mr-1"></i> Prev
                    </Button>
                  </div>
                  <div>
                    <Link
                      to={`/dashboard/${this.state.encryptedString}`}
                      className="btn btn-secondary text-dark"
                      style={{ borderRadius: "7px", color: "#000", fontWeight: "500", textDecoration: "none" }}
                    >
                      {localStorage.setItem("twofa", true)}
                      Exit
                    </Link>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

export default OnBoardingHealth;
