import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/addbeneficiaryform.css";
import { userService } from "../services/user.service";
import { contributorsService } from "../services/contributors.service";
import "../components/asterisk.css";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import loaderImg from "./images/loader.gif";
import LeftSidebar from "./../components/LeftSidebar";
import { ToastsStore } from 'react-toasts';
import { Col, Row, Form, FormGroup, Label, Input, Button, Popover, PopoverHeader, PopoverBody, } from "reactstrap";
import { encrypt_url } from "../helpers/encrypt-url";

class AddBenefeciary extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.submitContributorsForm = this.submitContributorsForm.bind(this);
    this.validateForm = this.validateForm.bind(this);

    // this.handleAddContributors = this.handleAddContributors.bind(this);

    this.togglePopOver = this.togglePopOver.bind(this);
    this.nextFormContributor = this.nextFormContributor.bind(this)

    this.state = {
      dropdownOpen: false,
      BenefeciaryList: [],
      helpOver: false,
      fields: [],
      errors: {},
      userObj: JSON.parse(user),
      encryptedString: encrypt_url(),
      divFormOne:'flow',
      divFormTwo:'none',
      errorFirstName:'',  
      errorLastName:'', 
      errorEmail:'', 
      errorLastFour:'',  
      errorMailAddress:'',  
      errorStatecity:'',  
      errorCity:'',  
      errorZip:'',  
  
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  togglePopOver() {
    this.setState({
      helpOver: !this.state.helpOver,
    });
  }

  handleLogout() {
    if (this.state.dropdownOpen) {
      userService.logout(this.props);
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
   
    if(e.target.name == "fname" && !e.target.value){
      this.setState({
        errorFirstName:'First name is required'
      })
      return false
    }else{
      this.setState({
        errorFirstName:''
      })
    }

    if(e.target.name == "lname" && !e.target.value){
      this.setState({
        errorLastName:'Last name is required'
      })
      return false
    }else{
      this.setState({
        errorLastName:''
      })
    }

    if(e.target.name == "email" && !e.target.value){
      this.setState({
        errorEmail:'Email is required'
      })
      return false
    }else{
      this.setState({
        errorEmail:''
      })
    }

    if(e.target.name == "last_4_beneficiary" && !e.target.value){
      this.setState({
        errorLastFour:'Last name is required'
      })
      return false
    }else{
      this.setState({
        errorLastFour:''
      })
    }

    if(e.target.name == "mail_address" && !e.target.value){
      this.setState({
        errorMailAddress:'Mail address is required'
      })
      return false
    }else{
      this.setState({
        errorMailAddress:''
      })
    }
    
    if(e.target.name == "statecity" && !e.target.value){
      this.setState({
        errorStatecity:'State is required'
      })
      return false
    }else{
      this.setState({
        errorStatecity:''
      })
    }

    if(e.target.name == "city" && !e.target.value){
      this.setState({
        errorCity:'City is required'
      })
      return false
    }else{
      this.setState({
        errorCity:''
      })
    }
     
    if(e.target.name == "zip" && !e.target.value){
      this.setState({
        errorZip:'Zip is required'
      })
      return false
    }else{
      this.setState({
        errorZip:''
      })
    }
  }

  submitContributorsForm = (e) => {
    e.preventDefault();
    if (this.validateForm()) {
      
      let fields = [];
      fields["email"] = "";
      fields["last_4_beneficiary"] = "";
      fields["zip"] = "";
      this.setState({ fields: fields });
    }

    this.handleAddContributors();
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (typeof fields["last_4_beneficiary"] !== "undefined") {
      if (!fields["last_4_beneficiary"].match(/^[0-9]{4}$/)) {
        formIsValid = false;
        errors["last_4_beneficiary"] = "*Please enter 4 numeric number only.";
      }
    }

    if (typeof fields["zip"] !== "undefined") {
      if (!fields["zip"].match(/^[0-9]{5}$/)) {
        formIsValid = false;
        errors["zip"] = "*Please enter valid zip no.";
      }
    }

    if (typeof fields["email"] !== "undefined") {
      if (
        !fields["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email ";
      }
    }

    this.setState({
      errors: errors,
    });
 
    return formIsValid;
  }

  validEmailFormat = (e) => {
    let emailFormat = e.target.value;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(emailFormat)) {
      return false;
    } else {
      return true;
    }
  };

  handleAddContributors = () =>{
    console.log('mail address::, ')
    const first_name = document.getElementById("firstname").value;
    const last_name = document.getElementById("lastname").value;
    const email = document.getElementById("email").value;
    const last_4_beneficiary = document.getElementById("last_4_beneficiary").value;
    
    const mail_address = document.getElementById("mail_address").value;
    const mail_address2 = document.getElementById("mail_address2").value;
    const city = document.getElementById("city").value;
    const state = document.getElementById("cityState").value;
    const zip = document.getElementById("zip").value;
    const full_name_encrypted = encrypt_url(first_name+' '+last_name);
    const email_encrypted = encrypt_url(email);
 
    if(!mail_address){
      this.setState({
        errorMailAddress:'Mailling address is required'
      })
      return false
    }else{
      this.setState({
        errorMailAddress:''
      })
    }

    if(!city){
      this.setState({
        errorCity:'City is required'
      })
      return false
    }else{
      this.setState({
        errorCity:''
      })
    }

    if(!state){
      this.setState({
        errorStatecity:'State is required'
      })
      return false
    }else{
      this.setState({
        errorStatecity:''
      })
    }

    if(!zip){
      this.setState({
        errorZip:'Zip is required'
      })
      return false
    }else{
      this.setState({
        errorZip:''
      })
    }
 
      let userId = this.state.userObj.data.user_id;
      let newId = contributorsService.storeContributorsUser(userId,  last_4_beneficiary, first_name, last_name,  mail_address,  mail_address2,  city, state, zip, email, full_name_encrypted, email_encrypted
      );
  

    this.props.history.push(`/contributors/${this.state.encryptedString}`);
  }

  nextFormContributor = () => {
    const first_name = document.getElementById("firstname");
    const last_name = document.getElementById("lastname");
    const email = document.getElementById("email");
    const last_4_beneficiary = document.getElementById("last_4_beneficiary");

    if (!first_name.value || !last_name.value || !email.value || !last_4_beneficiary.value) {
      ToastsStore.warning("Please fill in all required fields before proceeding.");
      return false;
    }
    if (last_4_beneficiary.value.length !== 4) {
      ToastsStore.warning("The last 4 contributor's social must be 4 digits.");
      return false;
    }

    this.setState({
      divFormOne: 'none',
      divFormTwo: 'flow'
    });
  }

  prevFormContributor = () =>{
    this.setState({
      divFormOne:'flow',
      divFormTwo:'none'
    })
  }

  render() {
    return (
      <div className="container-fluid" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-AddContributor</title>
          <link
            rel="addbenefeciary"
            href="http://thisheart.co/addbenefeciary"
          />
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="pl-2 mt-2 ">
            <Row>
              <Col sm="8" className=" ">
                <div className="row mr-2">
                  <div className="col-md-5">
                    <h1 className="memories-header">CONTRIBUTORS </h1>
                    <h2 className="memories-header-display">CONTRIBUTORS </h2>
                  </div>
                  <div className="col-md-7">
                    {/* <img src={lineOfColours} className="mt-4"/> */}
                  </div>
                </div>
             
                <p className="mt-2">
                  Here you’ll add your beneficiaries. Your beneficiaries are the
                  people who will have access to your info when you go.
                </p>
              </Col>
              <Col sm="4" className="link-column" />
            </Row>
          </div>


          <div className=" ">
            <div className="container-fluid">
              <div className=" ">
              <Form method="post"  className=" " onSubmit={this.submitContributorsForm} >

                  <div id="divFormOne" style={{display:this.state.divFormOne}}>
                    <Row form>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">First Name</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="fname"
                            id="firstname"
                            required
                            placeholder=" First Name"
                            onChange={this.handleChange}
                          />
                          <small className="text-danger">{this.state.errorFirstName}</small>
                        </FormGroup>
                      </Col>

                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Email Address</Label>
                          <Input
                            className="form-control-benefe"
                            type="email"
                            name="email"
                            id="email"
                            required
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                          <div className="errormessage">
                            {this.state.errors.email}
                          </div>
                          <small className="text-danger">{this.state.errorEmail}</small>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row form>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Last Name</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="lname"
                            id="lastname"
                            required
                            placeholder="Last Name"
                            onChange={this.handleChange}
                          />
                          <small className="text-danger">{this.state.errorLastName}</small>
                        </FormGroup>
                      </Col>

                      <Col md={6} sm={12} xs={12}>

                        <FormGroup className="required">
                          <Label className="control-label">
                            Last 4 of Contributor's social
                          </Label>
                          <span id="Popover">uh,why do you need this?</span>
                          <Popover
                            placement="top"
                            isOpen={this.state.helpOver}
                            target="Popover"
                            toggle={this.togglePopOver}
                          >
                            <PopoverHeader>
                              Do need this?
                              <span
                                className="dismissPop"
                                onClick={this.togglePopOver}
                              >
                                x
                              </span>
                            </PopoverHeader>
                            <PopoverBody>
                              It will show your social security number, you will
                              notified by email.
                            </PopoverBody>
                          </Popover>
                          <Input
                            className="form-control-benefe"
                            type="number"
                            name="last_4_beneficiary"
                            id="last_4_beneficiary"
                            required
                            placeholder="Last 4 social"
                            value={this.state.last_4_beneficiary}
                            onChange={this.handleChange}
                            maxLength="4"
                            minLength="4"
                          />
                          <div className="errormessage">
                            {this.state.errors.last_4_beneficiary}
                          </div>
                          <small className="text-danger">{this.state.errorLastFour}</small>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="pad-btnarea mt-3">
                      <Col lg={6}>
                        <Button className="savebtn bg-thisheart" type="button" onClick={this.nextFormContributor}>
                          Next 
                        </Button>
                        <Link to={`/contributors/${this.state.encryptedString}`}>
                          <Button className="btnCancel">Cancel</Button>
                        </Link>
                      </Col>
                      <Col lg={6} />
                    </Row>
                  </div> 
                
                  <div id="divFormTwo" className="" style={{display:this.state.divFormTwo}}>
                   

                    <Row form>

                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Mailing Address</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="mail_address"
                            id="mail_address"
                            onChange={this.handleChange}
                            placeholder="Mailing Address"
                          />
                          <small className="text-danger">{this.state.errorMailAddress}</small>
                        </FormGroup>
                      </Col>

                      <Col md={6} sm={12} xs={12}>
                        
                        <FormGroup className="required">
                          <Label className="control-label">State</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="statecity"
                            id="cityState"
                            onChange={this.handleChange}
                            placeholder="State Name"
                          />
                          <small className="text-danger">{this.state.errorStatecity}</small>
                        </FormGroup>

                      </Col>
                    </Row>

                    <Row>
                      
                      <Col md={6} sm={12} xs={12} className="mt-2">
                        <FormGroup>
                          <Label>Mailing Address 2</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="mail_address2"
                            id="mail_address2"
                            placeholder="Apartment or Floor"
                          />
                        </FormGroup>

                      </Col>

                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Zip</Label>
                          <Input
                            className="form-control-benefe"
                            type="number"
                            name="zip"
                            id="zip"
                             
                            placeholder="Zip No"
                            value={this.state.zip}
                            onChange={this.handleChange}
                          />
                          <div className="errormessage">
                            {this.state.errors.zip}
                          </div>
                          <small className="text-danger">{this.state.errorZip}</small>
                        </FormGroup>
                      </Col>

                    </Row>


                    <Row>
                      
                      <Col md={6} sm={12} xs={12}>
  
                        <FormGroup className="required">
                          <Label className="control-label">City</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="city"
                            id="city"
                            placeholder="City Name"
                            onChange={this.handleChange}
                          />
                          <small className="text-danger">{this.state.errorCity}</small>
                        </FormGroup>
                      </Col>
 
                    </Row>


                    <Row className="pad-btnarea mt-3 mb-3">
                      <Col lg={6}>
                        <Button className="savebtn" type="button" onClick={this.prevFormContributor}>
                          Previous
                        </Button>

                        <Button className="btnCancel bg-thisheart" type="submit">
                          Save
                        </Button>

                        <Link to={`/contributors/${this.state.encryptedString}`}>
                          <Button className="btnCancel">Cancel</Button>
                        </Link>
                      </Col>
                      <Col lg={6} />
                    </Row>
                  </div>
                
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AddBenefeciary;
