import { authHeader } from "../helpers/auth-header";
import axios from "axios";
import { ToastsStore } from "react-toasts";
import apiService from "./api.service";

const meText = "Me";
const momText = "Mom";
const dadText = "Dad";
const partnerText = "Partner";

const apiUrl = apiService.apiUrl;

function getAllDiagnosisList() {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  var options = [];
  options.push({ value: 0, label: "Other", key: 0 });
  axios
    .get(`${apiUrl}/api/diagnosis/getAll`, header)
    .then((response) => {
      response.data.diagnosisInfo.map((item, i) =>
        options.push({ value: item.id, label: item.diagnosis_name, key: i })
      );
    })
    .catch(function (error) {
      // handle error
    });
  return options;
}

function getMedicalData(personType, userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let dataForPerson = [];
  let attachmentForPerson = [];
  axios
    .get(
      `${apiUrl}/api/medichistory/getAllByType/${personType}/${userId}`,
      header
    )
    .then((response) => {
      response.data.historyInfo.map((item) =>
        dataForPerson.push({
          id: item.id,
          value: item.diagnosis_id,
          label: item.diagnosis_name,
        })
      );

      response.data.attachment_lists.map((item) => {
        if (item.status === 1 && item.file_category === "medical_histories") {
          attachmentForPerson.push({
            id: item.id,
            file_title: item.file_title,
            file_description: item.file_description,
            file_type: item.file_type,
            file_url: item.file_url,
          });
        }
      });

      if (personType === meText) {
        self.setState({
          recordInfoMe: dataForPerson,
          itemselectedMe: dataForPerson,
          attachmentMe: attachmentForPerson,
          dataLoadingMe: true,
        });
      } else if (personType === momText) {
        self.setState({
          recordInfoMom: dataForPerson,
          itemselectedMom: dataForPerson,
          attachmentMom: attachmentForPerson,
          dataLoadingMom: true,
        });
      } else if (personType === dadText) {
        self.setState({
          recordInfoDad: dataForPerson,
          itemselectedDad: dataForPerson,
          attachmentDad: attachmentForPerson,
          dataLoadingDad: true,
        });
      } else if (personType === partnerText) {
        self.setState({
          recordInfoPartner: dataForPerson,
          itemselectedPartner: dataForPerson,
          attachmentPartner: attachmentForPerson,
          dataLoadingPart: true,
        });
      }
    })
    .catch(function (error) {
      // handle error
      if (personType === meText) {
        self.setState({ dataLoadingMe: true });
      } else if (personType === momText) {
        self.setState({ dataLoadingMom: true });
      } else if (personType === dadText) {
        self.setState({ dataLoadingDad: true });
      } else if (personType === partnerText) {
        self.setState({ dataLoadingPart: true });
      }
      console.log("error:::", error);
    });
}

async function storeMedicalData(personType, userId, selectedItem, recordInfo, self) {
  const header = {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: authHeader(),
    },
  };

  let other_item_text = "";
  let diagnosis_list = [];
  selectedItem.forEach((item) => {
    if (item.value > 0) {
      diagnosis_list.push(item.value);
    } else {
      other_item_text = item.label;
    }
  });

  const formPayload = new FormData();
  formPayload.append("file_type", "image");
  formPayload.append("user_id", userId);
  formPayload.append("member_type", personType);
  diagnosis_list.forEach((diagnosis) => {
    formPayload.append("diagnosis_list[]", diagnosis);
  });
  formPayload.append("imagesFile", self.state.selectedAttachedFileMe);

  try {
    const response = await axios.post(`${apiUrl}/api/medichistory/store`, formPayload, header);

    response.data.data.forEach((itemDB, i) => {
      recordInfo.push({
        id: itemDB.id,
        value: itemDB.diagnosis_id,
        label: itemDB.diagnosis_name,
        key: i,
      });
    });

    if (personType === meText) {
      self.setState({ recordInfoMe: recordInfo });
    } else if (personType === momText) {
      self.setState({ recordInfoMom: recordInfo });
    } else if (personType === dadText) {
      self.setState({ recordInfoDad: recordInfo });
    } else if (personType === partnerText) {
      self.setState({ recordInfoPartner: recordInfo });
    }

    ToastsStore.success("Medical Condition has been added successfully!");
  } catch (error) {
    console.error(other_item_text, error);
  }
}

function storeDiagonosisData(personType, selectedText, recordInfo, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  if (!selectedText) {
    return false;
  }
  let data = JSON.stringify({
    diagnosis_name: selectedText,
    description: "Other Data Added by user",
  });

  let newId = 0;
  axios
    .post(`${apiUrl}/api/diagnosis/store`, data, header)
    .then((response) => {
      newId = response.data.diagnosisInfo.id;
      recordInfo.push({ value: newId, label: selectedText });

      if (personType === meText) {
        self.setState({ itemselectedMe: recordInfo, msgError:'' });
      } else if (personType === momText) {
        self.setState({ itemselectedMom: recordInfo, msgError:'' });
      } else if (personType === dadText) {
        self.setState({ itemselectedDad: recordInfo, msgError:'' });
      } else if (personType === partnerText) {
        self.setState({ itemselectedPartner: recordInfo, msgError:'' });
      }
    })
    .catch(function (error) {
      console.log(error)
      self.setState({
        msgError:'Error on save medical history data!'
      })
    });

  return newId;
}

function deleteMedicalData(selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    id: selectedItemId,
  });

  console.log("selectedItemId " + selectedItemId);

  axios
    .post(`${apiUrl}/api/medichistory/deleteBy/${selectedItemId}`, data, header)
    .then((response) => {
      ToastsStore.success("Medical Condition has been deleted successfully!");

      return true;
    })
    .catch(function (error) {
      // handle error
      return false;
    });

  return true;
}

function saveHealthOnBoard(self, data) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };
  return axios.post(
    `${apiUrl}/api/medicalhistory/saveHealthOnBoard`,
    data,
    header
  );
}

function getDiagnosisInfoAll(self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };
  return axios.post(
    `${apiUrl}/api/medicalhistory/getDiagnosisInfoAll`,
    {},
    header
  );
}

export const medicalService = {
  getAllDiagnosisList,
  getMedicalData,
  storeMedicalData,
  deleteMedicalData,
  storeDiagonosisData,
  saveHealthOnBoard,
  getDiagnosisInfoAll,
};