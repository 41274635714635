import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./styles/homeIndex.css";
import "./styles/featmemoriespage.css";
import "./styles/memories.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Header from "../components/Header";
import LeftSidebar from "../components/LeftSidebar";
import apiService from "./../services/api.service";
import { memoriesService } from "../services/memories.service";
import viewVideoIcon from "../pages/images/thisheartweb/video-view.png";
import deleteVideoIcon from "../pages/images/thisheartweb/delete-video.png";
import { ToastsStore } from 'react-toasts';
import videoView from "./images/videoPlaceHolder.png";
import YouTube from 'react-youtube';
import loadVideoPlay from "../pages/images/loadVideoPlay.png";

const apiUrl = apiService.apiUrl;

class MemoriesVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recordLists: [],
      imageLists: [],
      videoLists: [],
      currentView: "Images",
      userObj: JSON.parse(localStorage.getItem("user")),
      userType: localStorage.getItem("userType"),
      activeTab: "5",
      user_id: "",
      beneficiary_id: "",
      primary_user_id: "",
      currentPage: 1,
      videosPerPage: 12,
      deleteRecord: false,
      deleteID: null,
      modalOpen: false,
      selectedVideo: null,
      deleteVid: false,
      videoToDelete: null,
      modalVideoPlayerVideoFile: "",
      user_type: localStorage.getItem("user_type"),
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.toggleDeleteVideo = this.toggleDeleteVideo.bind(this);
    this.deleteVideos = this.deleteVideos.bind(this);

    // Initialize videoRefs
    this.videoRefs = [];
  }

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    this.setState({
      user_id: this.state.userObj.data.user_id,
      beneficiary_id: "",
      primary_user_id: this.state.userObj.data.primary_user_id,
    });

    const query = new URLSearchParams(this.props.location.search);
    const actions = query.get("actions");
    if (actions === "social") {
      this.setState({ activeTab: "5" });
    } else if (actions === "image") {
      this.setState({ activeTab: "1" });
    } else if (actions === "video") {
      this.setState({ activeTab: "2" });
    } else if (actions === "letters") {
      this.setState({ activeTab: "4" });
    } else if (actions === "record") {
      this.setState({ activeTab: "5" });
    }

    // Fetch data after setting the initial state
    // memoriesService.getImageData(userId, this);
    memoriesService.getAllMemoriesData(this);
  }

  componentDidUpdate(prevProps, prevState) {
    // Load videos after state update and after component has re-rendered
    // if (prevState.videoLists !== this.state.videoLists) {
    //   this.videoRefs.forEach(video => {
    //     if (video) video.load();
    //   });
    // }
  }

  handleBackClick = () => {
    if (this.props.history.length > 1) {
      this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    } else {
      this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    }
  }

  isImageUrl = (filename) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(filename);
  };

  setViewToImage = () => {
    this.setState({ currentView: "images" });
  };

  truncateText = (htmlContent, wordLimit) => {
    const div = document.createElement("div");
    div.innerHTML = htmlContent;
    const textContent = div.textContent || div.innerText || "";
    const words = textContent.split(" ");
    return words.length > wordLimit
      ? `${words.slice(0, wordLimit).join(" ")}...`
      : textContent;
  };

  handlePageChange = (pageNumber) => {
    this.setState({
      currentPage: pageNumber,
    });
  };

  openModal = (video) => {
    this.setState({
      modalOpen: true,
      selectedVideo: video,
      title: video.title,
      description: video.description,
    });
  };

  closeModal = () => {
    this.setState({
      modalOpen: false,
      selectedVideo: null,
    });
  };

  toggleDeleteVideo(videoID) {
    this.setState((prevState) => ({
      deleteVid: !prevState.deleteVid,
      videoToDelete: videoID,
    }));
  }

  // deleteVideos() {
  //   const tempVidID = this.state.videoToDelete;
  //   let successDel = memoriesService.deleteVideoData(tempVidID);

  //   if (successDel) {
  //     const videoLists = this.state.videoLists.filter(
  //       (video) => video.id !== tempVidID
  //     );
  //     this.setState({
  //       videoLists,
  //       deleteVid: false,
  //     });
  //   } else {
  //     ToastsStore.warning("Sorry! Video is not deleted.");
  //   }
  // }

  deleteVideos() {
    this.setState({videoLists:""});
    const { videoLists, videosPerPage, currentPage } = this.state;
    const tempVidID = this.state.videoToDelete;
    let successDel = memoriesService.deleteVideoData(tempVidID);
    
    console.log('video list:::', tempVidID)
  
    if (successDel) {
      const updatedVideoLists = videoLists.filter(video => video.id !== tempVidID);
      const totalPages = Math.ceil(updatedVideoLists.length / videosPerPage);
      const newPage = currentPage > totalPages ? totalPages : currentPage;
      console.log('updatedVideoLists list:::', updatedVideoLists)
      this.setState({
        videoLists: updatedVideoLists,
        deleteVid: false,
        currentPage: newPage,
      });
    } else {
      this.setState({
        videoLists: videoLists,
      });
      ToastsStore.warning("Sorry! Video is not deleted.");
    }
  }
  

  setVideoRef = (element) => {
    if (element) {
      this.videoRefs.push(element);
    }
  }

  toggleVideoPlayer = (videoFile) => {
    let videoFilePath = ""
    if (videoFile.urlcheck === "videoLink") {
      let fileWatch = videoFile.filename ? videoFile.filename.split("watch?v=") : null;
      let fileEmbed = videoFile.filename ? videoFile.filename.split("embed/") : null;
      let fileYoutu = videoFile.filename ? videoFile.filename.split("youtu.be/") : null;
      let fileShorts = videoFile.filename ? videoFile.filename.split("shorts/") : null;

      if (fileWatch && fileWatch[1]) {
        videoFilePath = fileWatch[1];
      }
      if (fileEmbed && fileEmbed[1]) {
        videoFilePath = fileEmbed[1];
      }
      if (fileYoutu && fileYoutu[1]) {
        videoFilePath = fileYoutu[1];
      }
      if (fileShorts && fileShorts[1]) {
        videoFilePath = fileShorts[1];
      }
    } else {
      videoFilePath = videoFile.filename
    }

    this.setState((prevState) => ({
      modalVideoPlayer: !prevState.modalVideoPlayer,
      modalVideoPlayerType: videoFile.urlcheck,
      modalVideoPlayerUrl: videoFilePath,
      modalVideoPlayerVideoFile: videoFile,
    }));
  };

  getErrorPlaceholder = () =>{
    return `this.onerror=null; this.src=${videoView};`
  }


  videoPlayerContent = () => {
    let playerContent = ""
    if (this.state.modalVideoPlayerType === "videoLink") {

      const opts = {
        height: '450',
        width: '100%',
        playerVars: {
          https:'//developers.google.com/youtube/player_parameters',
          autoplay: 1,
        },
      };

      playerContent = (
        <YouTube videoId={this.state.modalVideoPlayerUrl} opts={opts} autoplay={true} onReady={this._onReady} />
      )
    } else {
      playerContent = (
        <video
          width="100%"
          height="90%"
          controls
          poster={loadVideoPlay}
          style={{
            outline: "none",
          }}
        >
          <source src={apiUrl + "/" + this.state.modalVideoPlayerUrl} />
        </video>)
    }
    return playerContent
  }


  renderVideosWithPlaceholders = () => {
    const { videoLists, currentPage, videosPerPage, user_type } = this.state;
    const startIndex = (currentPage - 1) * videosPerPage;
    const endIndex = startIndex + videosPerPage;
    const videosToDisplay = videoLists.slice(startIndex, endIndex);
    let reversedVideosToDisplay = videosToDisplay;
    if (user_type === 'contributor' || user_type === 'bn') {
      reversedVideosToDisplay = [...videosToDisplay].reverse();
    }
    const customBorderRadiusStyle = {
      border:'1px solid #ccc',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '30px',
      borderBottomLeftRadius: '30px',
      borderBottomRightRadius: '0px',
    };

    return (
      <div className="row" id="mediaView" onClick={this.scrollToCarouselViewer}>
        {reversedVideosToDisplay.map((video, index) => {

            const opts = {
              height: '170',
              width: '100%',
              playerVars: {
                autoplay: 0,
                controls: 0,
                disablekb: 1,
                modestbranding: 1,
                rel: 0,
                showinfo: 0
              },
            };

            let videoFilePath = "";

            let fileWatch = video.filename ? video.filename.split("watch?v=") : null;
            let fileEmbed = video.filename ? video.filename.split("embed/") : null;
            let fileYoutu = video.filename ? video.filename.split("youtu.be/") : null;
            let fileYoutS = video.filename ? video.filename.split("shorts/") : null;

            if (fileWatch && fileWatch[1]) {
              videoFilePath = fileWatch[1];
            }
            if (fileEmbed && fileEmbed[1]) {
              videoFilePath = fileEmbed[1];
            }
            if (fileYoutu && fileYoutu[1]) {
              videoFilePath = fileYoutu[1];
            }
            if (fileYoutS && fileYoutS[1]) {
              videoFilePath = fileYoutS[1];
            }


            return  video.urlcheck=="videoLink"?
          
             (<div key={index} className="video-container col-lg-3 col-sm-12 col-xs-12 col-md-6 mb-3">
                <div className="video-thumbnail memories-video-container" style={customBorderRadiusStyle}>

                  <YouTube videoId={videoFilePath} opts={opts} onReady={this._onReady} />
 
                 {user_type === "primary" && (
                    <div onClick={this.toggleDeleteVideo.bind(this, video.id)} className="delete-icon">
                      <img src={deleteVideoIcon} alt="Delete Video" style={{ width: '24px', height: '24px' }} />
                    </div>
                  )}

                  {video.user_id === this.state.user_id && (
                    <div onClick={this.toggleDeleteVideo.bind(this, video.id)} className="delete-icon">
                      <img src={deleteVideoIcon} alt="Delete Video" style={{ width: '24px', height: '24px' }} />
                    </div>
                  )}

                </div>
                <div className="px-2 font-weight-bold text-muted">
          
                  {video.title?video.title:''}
                  {video.created_at?video.created_at:''}
                </div>
              </div>
            ):(
              <div key={index} className="video-container col-lg-3 col-sm-12 col-xs-12 col-md-6 mb-3">
                <div className="video-thumbnail memories-video-container" style={customBorderRadiusStyle}>
                  <img src={`${video.thumbnail_url}`} alt={video.title} className="responsive-mem" style={{ objectFit: 'cover', width: '100%', height: '100%', aspectRatio: '1/1' }} />
                  <div style={{ position: 'absolute', top: '15px', left: '10px', color: 'white', fontWeight: 'bold' }}>
                    {video.title ? video.title : ''}
                  </div>
                  <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', cursor: 'pointer' }} onClick={() => this.openModal(video)}>
                    <img src={viewVideoIcon} alt="View Video" style={{ width: '24px', height: '24px' }} />
                  </div>
                  {(user_type === "primary" || video.user_id === this.state.user_id) && (
                    <div onClick={this.toggleDeleteVideo.bind(this, video.id)} className="delete-icon">
                      <img src={deleteVideoIcon} alt="Delete Video" style={{ width: '24px', height: '24px' }} />
                    </div>
                  )}
                </div>
              </div>
            )
      
          }
        )}
      </div>
    );
  }

  renderPagination = () => {
    const { videoLists, currentPage, videosPerPage } = this.state;
    const totalPages = Math.ceil(videoLists.length / videosPerPage);

    // If the total number of pages is 1 or less, don't render pagination
    if (totalPages <= 1) {
      return null;
    }

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <ul className="pagination justify-content-center">
        {pageNumbers.map((number) => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button onClick={() => this.handlePageChange(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  render() {
    const { modalOpen, selectedVideo, title, description, deleteVid } = this.state;

    return (
      <div
        className="home-container"
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Videos</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />

        <div className="page-wrapper" style={{ display: "block" }}>
          <div className="container-fluid mt-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
             
                </div>
                <div className="col-6">
                  <div className="d-flex col-lg-11 justify-content-end align-items-center go-back-padding-video">
                    <button type="button" className="btn add-btn-mem mt-2 mr-3 shadow-sm" onClick={this.handleBackClick}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-left mr-1" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                      </svg>
                      <span><span className="go-back-text">Go</span> Back </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.renderVideosWithPlaceholders()}
            <div>
              {this.renderPagination()}
            </div>
          </div>
        </div>

        <Modal isOpen={modalOpen} toggle={this.closeModal} className="video-modal">
          <ModalHeader toggle={this.closeModal}>Video</ModalHeader>
          <ModalBody>
            <div className="font-weight-bold">{title}</div>
            <div>{description}</div>
            {selectedVideo && (
              <video src={selectedVideo.video_url} width="100%" height="auto" controls autoPlay>
                 
                Your browser does not support the video tag.
              </video>
            )}
          </ModalBody>
          <ModalFooter>
            <Button style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }} onClick={this.closeModal}>Close</Button>
          </ModalFooter>
        </Modal>

        <div>
          <Modal
            isOpen={deleteVid}
            toggle={this.toggleDeleteVideo}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteVideo}>
              Memories Video
            </ModalHeader>
            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              <p>Are you sure you want to delete this item?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteVideos}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteVideo}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>


        
        {/************************* Modal Video player  *******************************/}

        <div>
          <Modal isOpen={this.state.modalVideoPlayer} toggle={this.toggleVideoPlayer} className={this.props.className + ' modal-lg'}>
            <ModalHeader toggle={this.toggleVideoPlayer}>{this.state.modalVideoPlayerVideoFile.title} </ModalHeader>

            <ModalBody className="form-popup" style={{ overflow: 'hidden' }}>
              {
                this.videoPlayerContent()
              }

            </ModalBody>
            <ModalFooter>

              <Button
                color="primary"
                type="button"
                onClick={this.toggleVideoPlayer}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

      </div>
    );
  }
}

export default withRouter(MemoriesVideos);
