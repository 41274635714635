import React, { Component } from "react";
import { Media } from "reactstrap";
import { Link } from "react-router-dom";
import "../components/header.css";
import logoImage from "../pages/images/thisheart_img.svg";
import logoText from "../pages/images/thisheart_group.svg";
import profilePhoto from "../pages/images/dp.png";
import { userService } from "../services/user.service";
// import "../pages/styles/dashboardStyle.min.css";
// import { memoriesService } from "../services/memories.service";
import apiService from "./../services/api.service";
import { ToastsContainer, ToastsStore } from "react-toasts";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
} from "reactstrap";

import { decrypt_url, encrypt_url } from "../helpers/encrypt-url";
import PackagesServices from "../services/packages.service";
import $ from 'jquery';
import applyCustomJQuery from "../components/AppJquery"
import "../pages/styles/dashboardStyle.min.css";

class Header extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    let path_str = window.location.pathname.split("/");
    this.state = {
      uploadBtnSts: "false",
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      dropdownOpen: false,
      dropNav: false,
      navBar: false,
      profile_image: "",
      modalLogout: false,
      bnNavItem: false,
      searchText: "",
      user_package: "",
      package_info: "",
      billing_details: "",
      package_remaining_days: "",
      encryptedString: encrypt_url(path_str[1]),
      isBeneficiaryEntryPath: window.location.pathname.includes("beneficiaryentry"),

    };
    decrypt_url(this.state.encryptedString);
  }

  componentDidMount() {
    const userData = {}
    userService.getUserDetails(this, userData)
    if (localStorage.getItem("user_type") === "primary") {

      this.userPackageDays();
    }

    let user = localStorage.getItem("user");
    if (user) {
      const profile_image = JSON.parse(user).data.profile_image
      if (profile_image && profile_image.substr(0, 17) == "/static/media/dp.") {
        this.setState({
          profile_image: profilePhoto,
        });

      } else if (profile_image) {
        this.setState({
          profile_image: profile_image,
        });
      } else {
        this.setState({
          profile_image: profilePhoto,
        });
      }

    } else {
      this.props.history.push("/login");
    }
    applyCustomJQuery();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile_image) {
      let user = localStorage.getItem("user");
      const profile_image = JSON.parse(user).data.profile_image
      if (profile_image && profile_image.substr(0, 17) == "/static/media/dp.") {
        this.setState({
          profile_image: profilePhoto,
        });

      } else if (profile_image) {
        this.setState({
          profile_image: profile_image,
        });
      } else {
        this.setState({
          profile_image: profilePhoto,
        });
      }

    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  handleLogout = () => {
    userService.logout(this.props);
  };

  showPreview = () => {
    this.setState((prevState) => ({
      bnPreview: !prevState.bnPreview,
    }));
  };



  editProfile = () => {
    this.props.history.push(`/editProfile/${this.state.encryptedString}`);
  };

  toggleLogout = () => {
    this.setState((prevState) => ({
      modalLogout: !prevState.modalLogout,
    }));
  };

  openPackageSelection = () => {
    this.props.history.push({
      pathname: `/packagesubscriptionpayment/${this.state.encryptedString}`,
      billing_details_id: this.state.billing_details.id
    });
  };


  userPackageDays = () => {
    PackagesServices.userPackageDays(this).then(
      (response) => {
        this.setState({
          package_remaining_days: response.data.remaining_days,
          package_info: response.data.package_info,
          billing_details: response.data.billing_details,
        });
        localStorage.setItem("package_info", response.data.package_info);
        console.log('localStorage.getItem("user_type"):::', localStorage.getItem("user_type"))
        this.expireAlert()
      },
      (error) => {
        //console.log(error);
      }
    );
  };

  expireAlert = () => {
   
    if (
      this.state.package_remaining_days !== "" &&
      this.state.package_remaining_days < 2
    ) {
      localStorage.setItem('trial_process', 'yes')
      this.props.history.push('/trialpriceplan')

    } else {
       
    }

  };

  paymentAlert = () => {
    if (this.state.billing_details) {
      return (
        <div className="text-center bg-light text-danger">
          Oops! We’re having an issue with your payment method.
          Go ahead and update your info now.{" "}
          <button href="/#" style={{ height: '30px', paddingTop: '0px' }} className="btn btn-link" onClick={this.openPackageSelection}>click here</button>
        </div>
      );

    }
  };

  getCorrectPath = () => {
    const pathname = window.location.pathname;

    if (pathname.includes("beneficiarylanding")) {
      return `/beneficiarylanding/${this.state.encryptedString}`;
    } else if (pathname.includes("beneficiaryentry")) {
      return `/beneficiaryentry/${this.state.encryptedString}`;
    } else if (pathname.includes("verifyqrcode")) {
      return `/verifyqrcode`;
    } else if (pathname.includes("verifytwofa")) {
      return "/verifytwofa";
    }
    return `/beneficiarylanding/${this.state.encryptedString}`;
  }

  isVerifyQrCodePath() {
    const pathName = window.location.pathname;
    return pathName.includes("verifyqrcode") || pathName.includes("verifytwofa") || pathName.includes("verifytwofa");
  }

  isLandingPagePath() {
    const pathName = window.location.pathname;
    return true || pathName.includes("dashboard") || pathName.includes("memories") || pathName.includes("beneficiarylanding");
  }

  render() {
    return (
      <div className="" id="mediaViewer">
        {/* <div id="main-wrapper" className="header-bg fixed-top"> */}
        {this.paymentAlert()}
        {this.state && this.state.userType === "bn" ? (
          <header className="topbar" data-navbarbg="skin6">
            <nav className="navbar top-navbar navbar-expand-md navbar-light">
              <div className={`navbar-header navbar-header-border`} data-logobg="skin6">
                <a className="nav-toggler waves-effect waves-light d-block d-md-none" href="#">
                  <i className="ti-menu ti-close"></i>
                </a>
                <a className="navbar-brand" style={{ padding: '0px' }} href={this.getCorrectPath()}>
                  {this.isLandingPagePath() && (
                    <b className="logo-icon">
                      <img src={logoImage} alt="homepage" className="logo-sm profile-photo one" />
                    </b>
                  )}
                  <span className="logo-text">
                    <img src={logoText} alt="homepage" className="dark-logo profile-photo" style={{ width: '100px' }} />
                  </span>
                </a>
                <a className="topbartoggler d-block d-md-none waves-effect waves-light" href="#" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="ti-more"></i>
                </a>
              </div>
              <div className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin6">
                <ul className="navbar-nav float-left mr-auto">
                  <li className="nav-item d-none d-md-block">
                    <a className="nav-link sidebartoggler waves-effect waves-light ml-2" href="#" data-sidebartype="mini-sidebar">
                      <i className="sl-icon-menu font-20"></i>
                    </a>
                  </li>
                </ul>
                {!this.state.isBeneficiaryEntryPath && (
                  <ul className="navbar-nav float-right">
                    {!this.isVerifyQrCodePath() && (
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={this.state.profile_image} alt="user" className="rounded-circle nav-profile-img" width="31" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                          <span className="with-arrow">
                            <span style={{ background: "#f6db20" }}></span>
                          </span>
                          <div className="d-flex no-block align-items-center p-15 text-white m-b-10" style={{ padding: '10px', background: "#f6db20" }}>
                            <div className="">
                              <img src={this.state.profile_image} alt="user" className="img-circle" width="60" />
                            </div>
                            <div className="m-l-10">
                              <h4 className="m-b-0">{this.state.userObj ? this.state.userObj.data.user_name : ''}</h4>
                              <p className=" m-b-0">{this.state.userObj ? this.state.userObj.data.data.email : ''}</p>
                            </div>
                          </div>
                          <a className="dropdown-item" href={`/editprofile/${this.state.encryptedString}`}>
                            <i className="ti-user m-r-5 m-l-5"></i> Update Profile</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href={`/resettwofaaskpass/${this.state.encryptedString}`}>
                            <i className="ti-settings m-r-5 m-l-5"></i> Two Factor Settings</a>
                          <div className="dropdown-divider"></div>
                          {this.state.userType != "bn" && (<a className="dropdown-item" href={`/onboard2fa`}>
                            <i className="ti-layout-cta-right m-r-5 m-l-5"></i> Onboarding</a>)}
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#" onClick={this.toggleLogout}>
                            <i className="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                        </div>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            </nav>
          </header>
        ) : (
          <header className="topbar" data-navbarbg="skin6">
            <nav className="navbar top-navbar navbar-expand-md navbar-light">
              {/* <div className="navbar-header" data-logobg="skin6"> */}
              <div className={`navbar-header ${(window.location.href.includes("dashboard") || window.location.href.includes("memories") || window.location.href.includes("accounts") || window.location.href.includes("medicalhistory") || window.location.href.includes("contributors") || window.location.href.includes("beneficiaries")) ? "navbar-header-border" : "navbar-header-border"}`} data-logobg="skin6">
                <a className="nav-toggler waves-effect waves-light d-block d-md-none" href="#">
                  <i className="ti-menu ti-close"></i>
                </a>
                <a className="navbar-brand" style={{ padding: '0px' }} href={`/dashboard/${this.state.encryptedString}`}>
                  {this.isLandingPagePath() && (
                    <b className="logo-icon">
                      <img src={logoImage} alt="homepage" className=" logo-sm profile-photo one" />
                    </b>
                  )}
                  <span className="logo-text">
                    <img src={logoText} alt="homepage" className="dark-logo logo-lg profile-photo  three" style={{ width: '100px' }} />
                  </span>
                </a>
                <a className="topbartoggler d-block d-md-none waves-effect waves-light" href="#" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                  <i className="ti-more"></i>
                </a>
              </div>
              <nav className="navbar-collapse collapse" id="navbarSupportedContent" data-navbarbg="skin6">
                <ul className="navbar-nav float-left mr-auto">
                  <li className="nav-item d-none d-md-block">
                    <a className="nav-link sidebartoggler waves-effect waves-light ml-2" href="#" data-sidebartype="mini-sidebar">
                      <i className="sl-icon-menu font-20"></i>
                    </a>
                  </li>
                </ul>
                <ul className="navbar-nav float-right">
                  {!this.isVerifyQrCodePath() && (
                    <>
                      {!(this.state.userType === "contributor") && (
                        <li className="nav-item dropdown">
                          <a className="nav-link dropdown-toggle mt-1" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i className="fa fa-users font-18"></i>
                          </a>
                          <div className="dropdown-menu dropdown-menu-right  animated bounceInDown" aria-labelledby="navbarDropdown2">
                            <a className="dropdown-item" href={`/beneficiaries/${this.state.encryptedString}`}>
                              <i className="fa fa-user mr-1"></i>Beneficiaries List</a>
                            <a className="dropdown-item" href={`/contributors/${this.state.encryptedString}`}>
                              <i className="fa fa-handshake mr-1"></i>Contributors List</a>
                          </div>
                        </li>
                      )}
                      <li className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle text-muted waves-effect waves-dark pro-pic" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <img src={this.state.profile_image} alt="user" className="rounded-circle nav-profile-img" width="31" />
                        </a>
                        <div className="dropdown-menu dropdown-menu-right user-dd animated flipInY">
                          <span className="with-arrow">
                            <span style={{ background: "#f6db20" }}></span>
                          </span>
                          <div className="d-flex no-block align-items-center p-15 text-white m-b-10" style={{ padding: '10px', background: "#f6db20" }}>
                            <div className="">
                              <img src={this.state.profile_image} alt="user" className="img-circle nav-profile-img" width="60" />
                            </div>
                            <div className="m-l-10">
                              <h4 className="m-b-0">{this.state.userObj ? this.state.userObj.data.user_name : ''}</h4>
                              <p className=" m-b-0">{this.state.userObj ? this.state.userObj.data.data.email : ''}</p>
                            </div>
                          </div>
                          <a className="dropdown-item" href={`/editprofile/${this.state.encryptedString}`}>
                            <i className="ti-user m-r-5 m-l-5"></i> Update Profile</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href={`/resettwofaaskpass/${this.state.encryptedString}`}>
                            <i className="ti-settings m-r-5 m-l-5"></i> Two Factor Settings</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href={`/onboard2fa`}>
                            <i className="ti-layout-cta-right m-r-5 m-l-5"></i> Onboarding</a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#" onClick={this.toggleLogout}>
                            <i className="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
                          {/* <div className="dropdown-divider"></div>
                          <div className="p-l-30 p-10">
                            <a href="#" className="btn btn-sm bg-primary btn-rounded text-white">View Profile</a>
                          </div> */}
                        </div>
                      </li>
                    </>
                  )}
                </ul>
              </nav>
            </nav>
          </header>
        )}
        {/* *********************** Navbar reactstrap end ********************************* */}

        <div>
          <Modal
            isOpen={this.state.bnPreview}
            toggle={this.showPreview}
            className={this.props.className}
          >
            <ModalHeader toggle={this.showPreview}>
              Preview Beneficiairy
            </ModalHeader>

            <ModalBody className="form-popup">
              Beneficiary Preview Show
            </ModalBody>
            <ModalFooter>
              <Button color="danger" type="button" onClick={this.showPreview}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* /****************************** Logout Pop Up Start ****************************/}
        <div>
          <Modal
            isOpen={this.state.modalLogout}
            toggle={this.toggleLogout}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleLogout}>LOGOUT</ModalHeader>

            <ModalBody className="logout-acc" style={{ overflow: 'hidden' }}>
              <p>Are you sure you want to log out?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" type="button" onClick={this.handleLogout}>
                Logout
              </Button>
              <Button style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }} type="button" onClick={this.toggleLogout}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* /****************************** Logout Pop Up End ****************************/}
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

export default Header;
