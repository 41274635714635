import React, { Component } from "react";
import { Media } from "reactstrap";
import { Link } from "react-router-dom";
import MemoriesIcon from '../assets/images/Memories.png';
import AccountsIcon from '../assets/images/Accounts.png';
import MedicalIcon from '../assets/images/Medical.png';
import ContributorsIcon from '../assets/images/Contributors.png';
import BeneficiaryIcon from '../assets/images/Beneficiary.png';
import "../components/header.css";
import "../pages/styles/dashboardStyle.min.css";
import logoImage from "../pages/images/logo-img.png";
import profilePhoto from "../pages/images/dp.png";
import { userService } from "../services/user.service";
// import { memoriesService } from "../services/memories.service";
import apiService from "./../services/api.service";
import { ToastsContainer, ToastsStore } from "react-toasts";


import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  Collapse,
} from "reactstrap";

import { decrypt_url, encrypt_url } from "../helpers/encrypt-url";
import PackagesServices from "../services/packages.service";

class LeftSidebar extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");
    let path_str = window.location.pathname.split("/");
    this.state = {
      uploadBtnSts: "false",
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      dropdownOpen: false,
      dropNav: false,
      navBar: false,
      profile_image: "",
      modalLogout: false,
      bnNavItem: false,

      user_package: "",
      package_info: "",
      billing_details: "",
      package_remaining_days: "",
      encryptedString: encrypt_url(path_str[1]),
      // decryptedString : decrypt_url()
    };
    decrypt_url(this.state.encryptedString);
  }

  componentDidMount() {
    if (localStorage.getItem("user_type") === "primary") {
      //this.userPackageDays();
    }

    let user = localStorage.getItem("user");
    if (user) {

      const profile_image = JSON.parse(user).data.profile_image
      if (profile_image && profile_image.substr(0, 17) == "/static/media/dp.") {
        this.setState({
          profile_image: profilePhoto,
        });

      } else if (profile_image) {
        this.setState({
          profile_image:   profile_image,
        });
      } else {
        this.setState({
          profile_image: profilePhoto,
        });
      }

 
    } else {
      this.props.history.push("/login");
    }
    this.expireAlert()
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.profile_image) {
      this.setState({ profile_image: nextProps.profile_image });
      console.log("profile image props: ", this.state.profile_image);
    }
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };

  toggleNav = () => {
    this.setState((prevState) => ({
      navBar: !prevState.navBar,
    }));
  };

  navDropToggle = () => {
    this.setState((prevState) => ({
      dropNav: !prevState.dropNav,
    }));
  };

  handleLogout = () => {
    userService.logout(this.props);
  };

  showPreview = () => {
    this.setState((prevState) => ({
      bnPreview: !prevState.bnPreview,
    }));
  };

  dropBenNavItem = () => {
    this.setState((prevState) => ({
      bnNavItem: !prevState.bnNavItem,
    }));
  };

  navDisableBnLink = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <NavItem style={{ listStyle: "none" }}>
          <Dropdown isOpen={this.state.bnNavItem} toggle={this.dropBenNavItem}>
            <DropdownToggle className="drop-menu" nav>
              Beneficiaries
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <Link
                  className={
                    this.props.location.pathname === "/beneficiaries"
                      ? "link-style-active  link-style-benefi"
                      : "link-style-benefi"
                  }
                  to={`/beneficiaries/${this.state.encryptedString}`}
                >
                  Beneficiary List
                </Link>
              </DropdownItem>
              {/* <DropdownItem onClick={this.showPreview}>
                Beneficiary Preview
              </DropdownItem> */}
            </DropdownMenu>
          </Dropdown>
        </NavItem>
      );
    }
  };
  handleOTPSettings = () => {
    this.props.history.push(`/resettwofaaskpass/${this.state.encryptedString}`);
  };

  handleProfileSetting = () => {
    this.props.history.push(`/editprofile/${this.state.encryptedString}`);
  };

  editProfile = () => {
    this.props.history.push(`/editProfile/${this.state.encryptedString}`);
  };

  toggleLogout = () => {
    this.setState((prevState) => ({
      modalLogout: !prevState.modalLogout,
    }));
  };

  openPackageSelection = () => {
    this.props.history.push({
      pathname: `/packagesubscriptionpayment/${this.state.encryptedString}`,
      billing_details_id: this.state.billing_details.id
    });
  };


  userPackageDays = () => {
    PackagesServices.userPackageDays(this).then(
      (response) => {
        this.setState({
          package_remaining_days: response.data.remaining_days,
          package_info: response.data.package_info,
          billing_details: response.data.billing_details,
        });
        localStorage.setItem("package_info", response.data.package_info);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  expireAlert = () => {
    if (
      this.state.package_remaining_days !== "" &&
      this.state.package_remaining_days < 2
    ) {
      this.props.history.push('/trialpriceplan')

    }
  };


  paymentAlert = () => {
    if (this.state.billing_details) {
      return (
        <div className="text-center bg-light text-danger">
          Oops! We’re having an issue with your payment method.
          Go ahead and update your info now.{" "}
          <button href="/#" style={{ height: '30px', paddingTop: '0px' }} className="btn btn-link" onClick={this.openPackageSelection}>click here</button>
        </div>
      );

    }
  };


  leftMenuPrimary = () =>{
    if(this.state.userType=="primary"){
      return(
        <aside className="left-sidebar" data-sidebarbg="skin6">
        <div className="scroll-sidebar ps-container ps-theme-default ps-active-y" data-ps-id="3bb38220-0567-ba7e-c2d3-dff702737b98">
          <nav className="sidebar-nav">
            <ul id="sidebarnav" className="in">
              <li>
                <div className="user-profile dropdown m-t-20">
                  <div className="user-pic">
                    <img src={this.state.profile_image} alt="users" className="rounded-circle img-fluid leftside-profile-img" />
                  </div>
                  <div className="user-content hide-menu m-t-10">
                    <h5 className="m-b-10 user-name font-medium text-wrap" style={{textOverflow:"ellipsis", overflow:"hidden"}}>{this.state.userObj.data.user_name}</h5>
                  </div>
                </div>
              </li>

              {/* left sidebar sections start */}

              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/memories/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={MemoriesIcon} alt="Memories" width="24" height="24" className="ml-1 sidebar-icon" />
                  <span className="ml-1 hide-menu">Memories</span>
                </a>
              </li>
              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/accounts/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={AccountsIcon} alt="Accounts" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Accounts</span>
                </a>
              </li>
              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/medicalhistory/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={MedicalIcon} alt="Medical" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Medical</span>
                </a>
              </li>
              {!(this.state.userType==="contributor") && (
                <li className="sidebar-item">
                  <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/contributors/${this.state.encryptedString}`} aria-expanded="false">
                    {/* <i className="fa fa-users"></i> */}
                    <img src={ContributorsIcon} alt="Contributors" width="24" height="24" className="ml-1 sidebar-icon" />  
                    <span className="ml-1 hide-menu">Contributors </span>
                  </a>
                </li>
              )}

              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/beneficiaries/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={BeneficiaryIcon} alt="Beneficiaries" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Beneficiaries</span>
                </a>
              </li>

              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/user-activities/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={BeneficiaryIcon} alt="Activities Log" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Activities Log</span>
                </a>
              </li>


            </ul>
          </nav>
          <div className="ps-scrollbar-x-rail" style={{ left: '0px', bottom: '0px' }}><div className="ps-scrollbar-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div></div><div className="ps-scrollbar-y-rail" style={{ top: '0px', height: '262px', right: '3px' }}><div className="ps-scrollbar-y" tabIndex="0" style={{ top: '0px', height: '24px' }}></div></div></div>
      </aside>
      )
    } 
  }

  leftMenuContributors = () =>{
    if(this.state.userType=="contributor"){
      return(
        <aside className="left-sidebar" data-sidebarbg="skin6">
        <div className="scroll-sidebar ps-container ps-theme-default ps-active-y" data-ps-id="3bb38220-0567-ba7e-c2d3-dff702737b98">
          <nav className="sidebar-nav">
            <ul id="sidebarnav" className="in">
              <li>
                <div className="user-profile dropdown m-t-20">
                  <div className="user-pic">
                    <img src={this.state.profile_image} alt="users" className="rounded-circle img-fluid leftside-profile-img" />
                  </div>
                  <div className="user-content hide-menu m-t-10">
                    <h5 className="m-b-10 user-name font-medium text-wrap" style={{textOverflow:"ellipsis", overflow:"hidden"}}>{this.state.userObj.data.user_name}</h5>
                  </div>
                </div>
              </li>

              {/* left sidebar sections start */}

              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/memories/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={MemoriesIcon} alt="Memories" width="24" height="24" className="ml-1 sidebar-icon" />
                  <span className="ml-1 hide-menu">Memories</span>
                </a>
              </li>
              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/accounts/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={AccountsIcon} alt="Accounts" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Accounts</span>
                </a>
              </li>
              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/medicalhistory/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={MedicalIcon} alt="Medical" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Medical</span>
                </a>
              </li>
              {!(this.state.userType==="contributor") && (
                <li className="sidebar-item">
                  <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/contributors/${this.state.encryptedString}`} aria-expanded="false">
                    {/* <i className="fa fa-users"></i> */}
                    <img src={ContributorsIcon} alt="Contributors" width="24" height="24" className="ml-1 sidebar-icon" />  
                    <span className="ml-1 hide-menu">Contributors </span>
                  </a>
                </li>
              )}

              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/beneficiaries/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={BeneficiaryIcon} alt="Beneficiaries" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Beneficiaries</span>
                </a>
              </li>

              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/user-activities/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={BeneficiaryIcon} alt="Activities Log" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Activities Log</span>
                </a>
              </li>

            </ul>
          </nav>
          <div className="ps-scrollbar-x-rail" style={{ left: '0px', bottom: '0px' }}><div className="ps-scrollbar-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div></div><div className="ps-scrollbar-y-rail" style={{ top: '0px', height: '262px', right: '3px' }}><div className="ps-scrollbar-y" tabIndex="0" style={{ top: '0px', height: '24px' }}></div></div></div>
      </aside>
      )
    } 
  }

  leftMenuBeneficiaries = () =>{
    if(this.state.userType=="bn"){
      return(
        <aside className="left-sidebar" data-sidebarbg="skin6">
        <div className="scroll-sidebar ps-container ps-theme-default ps-active-y" data-ps-id="3bb38220-0567-ba7e-c2d3-dff702737b98">
          <nav className="sidebar-nav">
            <ul id="sidebarnav" className="in">
              <li>
                <div className="user-profile dropdown m-t-20">
                  <div className="user-pic">
                    <img src={this.state.profile_image} alt="users" className="rounded-circle img-fluid leftside-profile-img" />
                  </div>
                  <div className="user-content hide-menu m-t-10">
                    <h5 className="m-b-10 user-name font-medium text-wrap" style={{textOverflow:"ellipsis", overflow:"hidden"}}>{this.state.userObj.data.user_name}</h5>
                  </div>
                </div>
              </li>

              {/* left sidebar sections start */}

              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/memories/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={MemoriesIcon} alt="Memories" width="24" height="24" className="ml-1 sidebar-icon" />
                  <span className="ml-1 hide-menu">Memories</span>
                </a>
              </li>
              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/accounts/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={AccountsIcon} alt="Accounts" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Accounts</span>
                </a>
              </li>
              <li className="sidebar-item">
                <a className="sidebar-link waves-effect waves-dark sidebar-link" href={`/medicalhistory/${this.state.encryptedString}`} aria-expanded="false">
                  <img src={MedicalIcon} alt="Medical" width="24" height="24" className="ml-1 sidebar-icon" />  
                  <span className="ml-1 hide-menu">Medical</span>
                </a>
              </li>
 
            </ul>
          </nav>
          <div className="ps-scrollbar-x-rail" style={{ left: '0px', bottom: '0px' }}><div className="ps-scrollbar-x" tabIndex="0" style={{ left: '0px', width: '0px' }}></div></div><div className="ps-scrollbar-y-rail" style={{ top: '0px', height: '262px', right: '3px' }}><div className="ps-scrollbar-y" tabIndex="0" style={{ top: '0px', height: '24px' }}></div></div></div>
      </aside>
      )
    } 
  }


  render() {
    
    return (
      <>
        {this.leftMenuPrimary()}
        {this.leftMenuContributors()}
        {this.leftMenuBeneficiaries()}
      </>
    );
  }
}

export default LeftSidebar;
