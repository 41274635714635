import { authHeader } from "../helpers/auth-header";
import axios from "axios";
import apiService from "./api.service";
import { ToastsStore } from "react-toasts";

const apiUrl = apiService.apiUrl;

export const accountsService = {
  storeAccountData,
  getAccountData,
  updateAccountData,
  deleteAccountData,
};

function storeAccountData(
  userId,
  accName,
  accType,
  acc_number,
  accUrl,
  accDescription,
  accUserName,
  accPassword,
  accountList,
  self
) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    user_id: btoa(userId),
    acc_type: btoa(accType),
    acc_name: btoa(accName),
    acc_number: btoa(acc_number),
    acc_url: btoa(accUrl),
    acc_description: btoa(accDescription),
    acc_user_name: btoa(accUserName),
    acc_password: btoa(accPassword),
  });

  
  let newId = 0;
  axios
    .post(`${apiUrl}/api/account/store`, data, header)
    .then((response) => {
      newId = response.data.data.id;
      
      let accDataInfo = response.data.data;
      accountList.push({
        id: accDataInfo.id,
        accountType: accDataInfo.acc_type,
        accName: accDataInfo.acc_name,
        accNumber: accDataInfo.acc_number,
        accUrl: accDataInfo.acc_url,
        desc: accDataInfo.acc_description,
        username: accDataInfo.acc_user_name,
        password: accDataInfo.acc_password,
        user_id: accDataInfo.user_id,
      });
     
      if (self.state.selectedAccountType === "all") {
        self.setState({
          accountList: accountList,
          accountListFiltered: accountList,
          accountListTempFiltered: accountList,
        });
        console.log("temp data: ", self.state.accountListFiltered);
      } else {
        const acc_type = self.state.selectedAccountType;
        let accountListTemp = accountList.filter(
          (item) => item.accountType === acc_type
        );
        self.setState({ accountListFiltered: accountListTemp });
        self.setState({ accountListTempFiltered: accountListTemp });
      }
      ToastsStore.success("Account information is saved successfully!");
    })
    .catch(function(error) {
      if (error.response.data.code === "exceeds-accounts") {
        ToastsStore.error(error.response.data.message);
      } else {
        ToastsStore.error(
          "Sorry, problem to save on account information!, " +
            error.response.data.message
        );
      }
    });

  return newId;
}

function getAccountData(userId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  self.setState({ dataLoading: true });
  var accountData = [];
  console.log("User ID:", userId);
  axios
    .post(`${apiUrl}/api/account/getByUserId`, {}, header)
    .then((response) => {
      console.log(response);
      response.data.map((item) =>
        accountData.push({
          id: item.id,
          user_id: item.user_id,
          accountType: item.acc_type,
          accName: item.acc_name,
          accUrl: item.acc_url,
          accNumber: item.acc_number,
          desc: item.acc_description,
          username: item.acc_user_name,
          password: item.acc_password,
        })
      );

      if (self.state.searchAccountType) {
        const acc_type = self.state.searchAccountType;
        let accountListTemp = accountData.filter(
          (item) => item.accountType === acc_type
        );
        self.setState({ accountListFiltered: accountListTemp });
        self.setState({ accountListTempFiltered: accountListTemp });
      } else {
        self.setState({ accountListFiltered: accountData });
        self.setState({ accountListTempFiltered: accountData });
      }

      self.setState({ accountList: accountData });
      self.setState({ dataLoading: false });
    })
    .catch(function(error) {
      console.log(error.message);
      self.setState({ dataLoading: false });
      self.props.history.push("/login");
    });
}

function updateAccountData(
  userId,
  selectedItemId,
  accName,
  accNumber,
  accType,
  accUrl,
  accDescription,
  accUserName,
  accPassword,
  self
) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    user_id: userId,
    acc_type: accType,
    acc_name: accName,
    acc_number: accNumber,
    acc_url: accUrl,
    acc_description: accDescription,
    acc_user_name: accUserName,
    acc_password: accPassword,
  });

  axios
    .post(`${apiUrl}/api/account/updateBy/${selectedItemId}`, data, header)
    .then((response) => {
      ToastsStore.success("Account updated successfully!");

      this.getAccountData(userId, self);
      return true;
    })

    .catch(function(error) {
      // handle error
    });
  return true;
}

function deleteAccountData(selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  };

  let data = JSON.stringify({
    id: selectedItemId,
  });

  axios
    .post(`${apiUrl}/api/account/deleteBy/${selectedItemId}`, data, header)
    .then((response) => {
      ToastsStore.success("Account has been deleted successfully!");
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}
