import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
import "./styles/maindashboard.css";
import "./styles/searchresult.css";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import BeneficiaryIcon from '../assets/images/Beneficiary.png';
import MemoriesIcon from '../assets/images/Memories.png';
import AccountsIcon from '../assets/images/Accounts.png';
import MedicalIcon from '../assets/images/Medical.png';
 
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "./styles/accounts.css";
import { encrypt_url } from "./../helpers/encrypt-url";
import { memoriesService } from "../services/memories.service";
import LeftSidebar from "./../components/LeftSidebar";


class SearchResult extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");
    this.toggle = this.toggle.bind(this);
    this.state = {
      userObj: JSON.parse(user),
      deleteID: 0,
      userType: localStorage.getItem("user_type"),
      listItem: [],
      listItemAll: [],
      encryptedString: encrypt_url(),
      searchText: "",
      searchType: "all",
      searchResultList: "",
      allMemories: "",
      allAccounts: "",
      allBeneficiaries: "",
      allMedicals: "",
      sortType:'by_name'
    };
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    const searchText = query.get("query");
    this.setState({
      searchText: searchText,
    });
   
    memoriesService.searchAll(this, searchText);
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  sortSearchList = (sort_type) => {
    let listOfSearch = this.state.listItem;

    if (sort_type === "by_name") {
      listOfSearch.sort((a, b) => {
        return a.title > b.title ? 1 : a.title === b.title ? 1 : -1;
      });
      this.setState({ listItem: listOfSearch });
      document.getElementById("chk_by_name").checked = true;
      document.getElementById("chk_by_type").checked = false;
      document.getElementById("chk_last_update").checked = false;
    }

    if (sort_type === "by_type") {
      listOfSearch.sort((a, b) => {
        return a.type > b.type ? 1 : a.type === b.type ? 1 : -1;
      });
      this.setState({ listItem: listOfSearch });
      document.getElementById("chk_by_name").checked = false;
      document.getElementById("chk_by_type").checked = true;
      document.getElementById("chk_last_update").checked = false;
    }
    if (sort_type === "last_update") {
      listOfSearch.sort((a, b) => {
        return a.date < b.date ? 1 : a.date === b.date ? 1 : -1;
      });
      this.setState({ listItem: listOfSearch });
      document.getElementById("chk_by_name").checked = false;
      document.getElementById("chk_by_type").checked = false;
      document.getElementById("chk_last_update").checked = true;
    }
    this.setState({
      sortType:sort_type
    })
  };

  showSortByItem = () => {
    let sortList = [];
    sortList = (
      <Form className="searchForm">
        <ul className="menu-list-src">
          <span style={{ color: "#ff7980", fontWeight: "bold" }}>Sort by</span>

          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_by_name"
                  name="checkAllItem"
                  className="check-item"
                  onClick={this.sortSearchList.bind(this, "by_name")}
                />
                <Button
                  color="link"
                  onClick={this.sortSearchList.bind(this, "by_name")}
                  style={{ color: "black" }}
                >
                  By name
                </Button>
              </Label>
            </FormGroup>
          </li>
          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_by_type"
                  name="checkAllItem"
                  className="check-item"
                  onClick={this.sortSearchList.bind(this, "by_type")}
                />
                <Button
                  color="link"
                  onClick={this.sortSearchList.bind(this, "by_type")}
                  style={{ color: "black" }}
                >
                  By type
                </Button>
              </Label>
            </FormGroup>
          </li>

          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_last_update"
                  name="checkAllItem"
                  className="check-item"
                  onClick={this.sortSearchList.bind(this, "last_update")}
                />

                <Button
                  color="link"
                  onClick={this.sortSearchList.bind(this, "last_update")}
                  style={{ color: "black" }}
                >
                  Last Update
                </Button>
              </Label>
            </FormGroup>
          </li>
        </ul>
      </Form>
    );
    return sortList;
  };

  showTypeItem = () => {
    let acctypeList = [];
    acctypeList = (
      <Form className="searchForm">
        <ul className="menu-list-src">
          <span style={{ color: "#ff7980", fontWeight: "bold" }}>Type</span>
          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_all"
                  name="checkAllItem"
                  className="check-item"
                  onChange={this.handleSearchFilterType.bind(this, "all")}
                  checked={this.state.searchType==='all'}
                />
                <span className="icn-pad">
                  <i className="fa fa-list" aria-hidden="true"></i>
                </span>
                <Button
                  color="link"
                  style={{ color: "black" }}
                  onClick={this.handleSearchFilterType.bind(this, "all")}
                >
                  All Search
                </Button>
              </Label>
            </FormGroup>
          </li>
          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_memories"
                  name="checkAllItem"
                  className="check-item"
                  onClick={this.handleSearchFilterType.bind(this, "memories")}
                />
                <span className="icn-pad">
                  <i className="fa fa-heart" aria-hidden="true"></i>
                </span>
                <Button
                  color="link"
                  style={{ color: "black" }}
                  onClick={this.handleSearchFilterType.bind(this, "memories")}
                >
                  Memories
                </Button>
              </Label>
            </FormGroup>
          </li>
          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_accounts"
                  name="checkAllItem"
                  className="check-item"
                  onClick={this.handleSearchFilterType.bind(this, "accounts")}
                />
                <span className="icn-pad">
                  <i className="fa fa-address-book" aria-hidden="true"></i>
                </span>
                <Button
                  color="link"
                  style={{ color: "black" }}
                  onClick={this.handleSearchFilterType.bind(this, "accounts")}
                >
                  Accounts
                </Button>
              </Label>
            </FormGroup>
          </li>
          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_medicals"
                  name="checkAllItem"
                  className="check-item"
                  onClick={this.handleSearchFilterType.bind(this, "medicals")}
                />
                <span className="icn-pad">
                  <i className="fa fa-stethoscope" aria-hidden="true"></i>
                </span>
                <Button
                  color="link"
                  style={{ color: "black" }}
                  onClick={this.handleSearchFilterType.bind(this, "medicals")}
                >
                  Medical
                </Button>{" "}
              </Label>
            </FormGroup>
          </li>

          <li>
            <FormGroup check>
              <Label check className=" ">
                <Input
                  type="checkbox"
                  id="chk_beneficiaries"
                  name="checkAllItem"
                  className="check-item"
                  onClick={this.handleSearchFilterType.bind(
                    this,
                    "beneficiaries"
                  )}
                />
                <span className="icn-pad">
                  <img src={BeneficiaryIcon} style={{height:'16px', width:'16px'}}/>
                </span>
                <Button color="link" style={{ color: "black" }} onClick={this.handleSearchFilterType.bind(this, "beneficiaries")}> Beneficiaries </Button>
              </Label>
            </FormGroup>
          </li>
        </ul>
      </Form>
    );
    return acctypeList;
  };

  changeString = (e) => {
    this.setState({
      searchText: e.target.value,
    });
  };

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      memoriesService.searchByTextDesktop(this, this.state.searchType);
    }  
  };

  searchHandle = (e) => {
    memoriesService.searchByTextDesktop(this, this.state.searchType);     
  };

  handleSearchFilterType = (searchType) => {
    const allList = this.state.listItemAll;
    if(searchType !== "all") {
      let listTmp = allList.filter((item) => {
        console.log('search result: ', item)
        if(item.type === searchType || (searchType === "memories" && item.content_type === "letters")){
          return true;
        }
      
      });
      
      this.setState({
        listItemAll: allList,
        listItem: listTmp,
        searchType: searchType
      });
      
      document.getElementById("chk_memories").checked = false;
      document.getElementById("chk_accounts").checked = false;
      document.getElementById("chk_medicals").checked = false;
      document.getElementById("chk_beneficiaries").checked = false;
      document.getElementById("chk_" + searchType).checked = true;
      document.getElementById("chk_all").checked = false;
    }else if (searchType === "all") {
      this.setState({
        listItem: allList,
        searchType: 'all'
      });
      document.getElementById("chk_all").checked = true;
      document.getElementById("chk_memories").checked = false;
      document.getElementById("chk_accounts").checked = false;
      document.getElementById("chk_medicals").checked = false;
      document.getElementById("chk_beneficiaries").checked = false;
    }

    this.sortSearchList.bind(this,this.state.sortType) 

  };

  clickSearchItem = (searchTerm) => {
    console.log(searchTerm);
    if (searchTerm.type === "memories") {
      this.props.history.push({
        pathname: "/memories-images/" ,//+ this.state.encryptedString,
        search: "?actions="+searchTerm.content_type+"&id=" + searchTerm.id,
      });
    } else if (searchTerm.type === "accounts") {
      this.props.history.push({
        pathname: "/accounts/" + this.state.encryptedString,
        search: "?id=" + searchTerm.id,
      });
    } else if (searchTerm.type === "beneficiaries") {
      this.props.history.push({
        pathname: "/beneficiaries/" + this.state.encryptedString,
        search: "?id=" + searchTerm.id,
      });
    } else if (searchTerm.type === "medicals") {
      this.props.history.push({
        pathname: "/medicalhistory/" + this.state.encryptedString,
        search: "?id=" + searchTerm.id,
      });
    }else if (searchTerm.type === "letters") {
      this.props.history.push({
        pathname: "/memories-images/" ,//+ this.state.encryptedString,
        search: "?actions=letters&id=" + searchTerm.id,
      });
    }
  };

  showSearchListItem = () => {
    let searchItemList = [];
    console.log('this.state.listItem 387=====:::',this.state.listItem)
    searchItemList = (
      <div className="row">
        {this.state.listItem.map((searchTerm, i) => (
          <React.Fragment key={i}>
            <div className="col-md-12 overflow-hidden border-bottom pt-3 pb-3 " onClick={this.clickSearchItem.bind(this, searchTerm)}>
              <div className="row">
                <div className="col-md-1">
   
                  {searchTerm.type === "memories" && searchTerm.content_type ==="image" && (
                    <img src={MemoriesIcon} style={{height:'16px', width:'16px'}}/>
                  )}

                  {searchTerm.type === "memories" && searchTerm.content_type ==="video" && (
                    <img src={MemoriesIcon} style={{height:'16px', width:'16px'}}/>
                  )}

                  {searchTerm.type === "memories" && searchTerm.content_type ==="record" && (
                    <img src={MemoriesIcon} style={{height:'16px', width:'16px'}}/>
                  )}

                  {searchTerm.type === "accounts" && (
                    <img src={AccountsIcon} style={{height:'16px', width:'16px'}}/>
                  )}
                  {searchTerm.type === "beneficiaries" && (
                    <img src={BeneficiaryIcon} style={{height:'16px', width:'16px'}}/>
                  )}
                  {searchTerm.type === "medicals" && (
                   <img src={MedicalIcon} style={{height:'16px', width:'16px'}}/>
                  )}

                  {searchTerm.type === "letters" && (
                    <img src={MemoriesIcon} style={{height:'16px', width:'16px'}}/>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="row">{searchTerm.title}</div>
                </div>
                <div className="col-md-5">
                  {searchTerm.date}
                </div>
              </div>
            </div>
            
          </React.Fragment>
        ))}
      </div>
    );
    return searchItemList;
  };

  render() {
    return (
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart- Dashboard Search</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="row">
            <div className="col-md-8 mt-4">
              <h1 className="ml-3 ">Search results</h1>
              <p className="ml-3">
                Click on results to access them in more detail or to make edits.
              </p>
            </div>
            <div className="col-md-4 mt-5 text-right">
              <a className="mr-3" href={`/dashboard/${this.state.encryptedString}`}> <span className="h4 mt-2"><b>&crarr;</b></span> Back to dashboard  </a>
            </div>
          </div>
        </div>
        <div className="page-wrapper" style={{ display: 'block',marginTop:'0px',paddingTop:'0px' }}>
          <div className="bg-search">
            <div className="container">
              <div className="row" style={{ alignItems: 'center' }}>
                <div className="col-md-4 p-txt">
                  <p style={{ marginBottom: "0px" }}>
                    Find your Stuff quickly by simple search
                  </p>
                </div>
                <div className="col-md-6" style={{ paddingRight: '3px' }}>
                  <div className="has-search">
                    <FormGroup style={{ marginBottom: "0px" }}>
                      <span className="fa fa-search form-control-feedback text-dark"></span>
                      <Input
                        type="search"
                        className="form-control-input"
                        placeholder="Search for"
                        onChange={this.changeString}
                        onKeyUp={this.handleEnter}
                        value={this.state.searchText}
                      />
                    </FormGroup>
                  </div>
                </div>
                {/* <div className="col-md-3" style={{display:'flex', alignItems:'center', paddingLeft:'0px'}}> */}
                <div className="col-md-2" style={{ paddingLeft: '0px' }}>
                  <Button style={{ background: "#e1c720", border: "none" }} onClick={this.searchHandle}>
                    Search
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="page-wrapper" style={{ display: 'block',marginTop:'0px',paddingTop:'30px' }}>

          <div className="row">
            <div className="col-md-2">
              <div>{this.showSortByItem()} </div>
              {this.showTypeItem()} 
            </div>
            <div className="col-lg-8" style={{ paddingTop: "20px" }}>
                {this.showSearchListItem()} 
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SearchResult;
