import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/medicalhistory.css";
import { userService } from "../services/user.service";
import Select from "react-select";
import { medicalService } from "../services/medicalhistory.service";
import apiUrl from "../services/api.service"
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import { ToastsStore } from "react-toasts";
import zeroState from "../pages/images/medical-placeholder.png";
import pdfIcon from "./images/pdf-icon.png";
import imgIcon from "./images/image-icon.jpg";



import {
  Col,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";
import { decrypt_url, encrypt_url } from "../helpers/encrypt-url";
import LeftSidebar from "../components/LeftSidebar";

var options = [];
var meText = "Me";
var momText = "Mom";
var dadText = "Dad";
var partnerText = "Partner";
class MedicalHistory extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.showMeInfolist = this.showMeInfolist.bind(this);
    this.showMomInfoList = this.showMomInfoList.bind(this);
    this.showDadInfoList = this.showDadInfoList.bind(this);
    this.showPartInfoList = this.showPartInfoList.bind(this);

    this.ShowMeItem = this.ShowMeItem.bind(this);
    this.toggleDeleteMeItem = this.toggleDeleteMeItem.bind(this);
    this.deleteMeItems = this.deleteMeItems.bind(this);

    this.handleItemChangeMe = this.handleItemChangeMe.bind(this);
    this.handleSelectedItemsMe = this.handleSelectedItemsMe.bind(this);

    this.ShowMomItem = this.ShowMomItem.bind(this);
    this.toggleDeleteMomItem = this.toggleDeleteMomItem.bind(this);
    this.deleteMomItems = this.deleteMomItems.bind(this);
    this.handleSelectedItemsMom = this.handleSelectedItemsMom.bind(this);

    this.ShowDadItem = this.ShowDadItem.bind(this);
    this.toggleDeleteDadItem = this.toggleDeleteDadItem.bind(this);
    this.deleteDadItems = this.deleteDadItems.bind(this);
    this.handleSelectedItemsDad = this.handleSelectedItemsDad.bind(this);

    this.ShowPartnerItem = this.ShowPartnerItem.bind(this);
    this.toggleDeletePartnerItem = this.toggleDeletePartnerItem.bind(this);
    this.deletePartnerItems = this.deletePartnerItems.bind(this);
    this.handleSelectedItemsPart = this.handleSelectedItemsPart.bind(this);
    this.handleOTPSettings = this.handleOTPSettings.bind(this);
    let path_str = window.location.pathname.split("/");
    this.state = {
      dataLoadingMe: false,
      dataLoadingMom: false,
      dataLoadingDad: false,
      dataLoadingPart: false,
      dropdownOpen: false,
      showme: false,
      itemselectedMe: [],
      itemselectedMom: [],
      itemselectedDad: [],
      itemselectedPartner: [],
      attachmentMe: [],
      attachmentPartner: [],
      attachmentDad: [],
      attachmentMom: [],
      inputText: "",

      meSaveItemBtn: false,
      momSaveItemBtn: false,
      dadSaveItemBtn: false,
      partSaveItemBtn: false,

      recordInfoMe: [],
      recordInfoMom: [],
      recordInfoDad: [],
      recordInfoPartner: [],

      uploadBtnSts: "false",
      deleteID: 0,
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
      selectedAttachedFileMe: '',
      selectedAttachedFilePartner: '',
      selectedAttachedFileMom: '',
      selectedAttachedFileDad: '',
      encryptedString: encrypt_url(path_str[1]),
    };
  }

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    options = medicalService.getAllDiagnosisList();

    medicalService.getMedicalData(meText, userId, this);
    medicalService.getMedicalData(momText, userId, this);
    medicalService.getMedicalData(dadText, userId, this);
    medicalService.getMedicalData(partnerText, userId, this);
  }

  handleOTPSettings() {
    this.props.history.push("/resettwofaaskpass");
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleLogout() {
    if (this.state.dropdownOpen) {
      userService.logout(this.props);
    }
  }

  ShowMeItem() {
    this.setState((prevState) => ({
      showme: !prevState.showme,
    }));
    this.setState({ meSaveItemBtn: false });
  }

  ShowMomItem() {
    this.setState((prevState) => ({
      showmom: !prevState.showmom,
    }));
    this.setState({ momSaveItemBtn: false });
  }

  ShowDadItem() {
    this.setState((prevState) => ({
      showdad: !prevState.showdad,
    }));
    this.setState({ dadSaveItemBtn: false });
  }

  ShowPartnerItem() {
    this.setState((prevState) => ({
      showpartner: !prevState.showpartner,
    }));

    this.setState({ partSaveItemBtn: false });
  }

  toggleOtherItemMe = () => {
    this.setState((prevState) => ({
      otherMeItem: !prevState.otherMeItem,
    }));
  };

  otherItemSaveMe = () => {
    let otherItem = document.getElementById("otherItem").value;
    const filterOtherItem = this.state.itemselectedMe.filter(
      (item) => item.label !== "Other"
    );
    if (otherItem.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      meText,
      otherItem,
      filterOtherItem,
      this
    );
    this.setState({ otherMeItem: false });
  };

  toggleOtherItemPartner = () => {
    this.setState((prevState) => ({
      otherPartnerItem: !prevState.otherPartnerItem,
    }));
  };

  otherItemSavePartner = () => {
    let otherPartner = document.getElementById("otherItemPart").value;

    const filterOtherItem = this.state.itemselectedPartner.filter(
      (item) => item.label !== "Other"
    );
    if (otherPartner.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      partnerText,
      otherPartner,
      filterOtherItem,
      this
    );
    this.setState({ otherPartnerItem: false });
  };

  toggleOtherItemMom = () => {
    this.setState((prevState) => ({
      otherItemMom: !prevState.otherItemMom,
    }));
  };

  otherItemSaveMom = () => {
    let otherMom = document.getElementById("otherItemMom").value;
    const filterOtherItem = this.state.itemselectedMom.filter(
      (item) => item.label !== "Other"
    );
    if (otherMom.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      momText,
      otherMom,
      filterOtherItem,
      this
    );
    this.setState({ otherItemMom: false });
  };

  toggleOtherItemDad = () => {
    this.setState((prevState) => ({
      otherItemDad: !prevState.otherItemDad,
    }));
  };

  otherItemSaveDad = () => {
    let otherDad = document.getElementById("otherItemDad").value;

    const filterOtherItem = this.state.itemselectedDad.filter(
      (item) => item.label !== "Other"
    );
    if (otherDad.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      dadText,
      otherDad,
      filterOtherItem,
      this
    );
    this.setState({ otherItemDad: false });
  };

  toggleDeleteMeItem(deleteID) {
    this.setState((prevState) => ({
      deleteMe: !prevState.deleteMe,
      deleteID: deleteID,
    }));
  }

  toggleDeleteMomItem(deleteID) {
    this.setState((prevState) => ({
      deleteMom: !prevState.deleteMom,
      deleteID: deleteID,
    }));
  }

  toggleDeleteDadItem(deleteID) {
    this.setState((prevState) => ({
      deleteDad: !prevState.deleteDad,
      deleteID: deleteID,
    }));
  }

  toggleDeletePartnerItem(deleteID) {
    this.setState((prevState) => ({
      deletePartner: !prevState.deletePartner,
      deleteID: deleteID,
    }));
  }

  toggleProfilePhoto = () => {
    this.setState((prevState) => ({
      profilePhoto: !prevState.profilePhoto,
    }));
  };

  showAddNewBtnMe = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-me" onClick={this.ShowMeItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  showAddNewBtnMom = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-mom" onClick={this.ShowMomItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  showAddNewBtnDad = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-dad" onClick={this.ShowDadItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  showAddNewBtnPartner = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-partner" onClick={this.ShowPartnerItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  handleItemChangeMe = (itemselectedMe) => {
    let me_othr = 0;
    const checkOther = itemselectedMe.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedMe.filter(
        (item) => item.label !== "Other"
      );
      let getText = this.toggleOtherItemMe();

      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          meText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ meSaveItemBtn: false });
        me_othr = 1;
      }
    } else {
      this.setState({ itemselectedMe });
    }

    if (itemselectedMe.length > 1 && me_othr === 1) {
      this.setState({ meSaveItemBtn: true });
    } else if (itemselectedMe.length > 0 && me_othr === 0) {
      this.setState({ meSaveItemBtn: true });
    } else {
      this.setState({ meSaveItemBtn: false });
    }
  };

  handleItemChangePart = (itemselectedPartner) => {
    let part_othr = 0;
    const checkOther = itemselectedPartner.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedPartner.filter(
        (item) => item.label !== "Other"
      );

      let getText = this.toggleOtherItemPartner();
      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          partnerText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ partSaveItemBtn: false });
        part_othr = 1;
      }
    } else {
      this.setState({ itemselectedPartner });
    }

    if (itemselectedPartner.length > 1 && part_othr === 1) {
      this.setState({ partSaveItemBtn: true });
    } else if (itemselectedPartner.length > 0 && part_othr === 0) {
      this.setState({ partSaveItemBtn: true });
    } else {
      this.setState({ partSaveItemBtn: false });
    }
  };

  handleItemChangeMom = (itemselectedMom) => {
    let mom_othr = 0;
    const checkOther = itemselectedMom.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedMom.filter(
        (item) => item.label !== "Other"
      );
      let getText = this.toggleOtherItemMom();
      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          momText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ momSaveItemBtn: false });
        mom_othr = 1;
      }
    } else {
      this.setState({ itemselectedMom });
    }

    if (itemselectedMom.length > 1 && mom_othr === 1) {
      this.setState({ momSaveItemBtn: true });
    } else if (itemselectedMom.length > 0 && mom_othr === 0) {
      this.setState({ momSaveItemBtn: true });
    } else {
      this.setState({ momSaveItemBtn: false });
    }
  };

  handleItemChangeDad = (itemselectedDad) => {
    let dad_othr = 0;
    const checkOther = itemselectedDad.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedDad.filter(
        (item) => item.label !== "Other"
      );
      let getText = this.toggleOtherItemDad();

      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          dadText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ dadSaveItemBtn: false });
        dad_othr = 1;
      }
    } else {
      this.setState({ itemselectedDad });
    }

    if (itemselectedDad.length > 1 && dad_othr === 1) {
      this.setState({ dadSaveItemBtn: true });
    } else if (itemselectedDad.length > 0 && dad_othr === 0) {
      this.setState({ dadSaveItemBtn: true });
    } else {
      this.setState({ dadSaveItemBtn: false });
    }
  };

  handleSelectedItemsMe() {
    if (this.state.itemselectedMe.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedMe.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }

    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      meText,
      userId,
      this.state.itemselectedMe,
      this.state.recordInfoMe,
      this
    );
    this.setState({ showme: false });
  }

  handleSelectedItemsMom() {
    if (this.state.itemselectedMom.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedMom.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }
    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      momText,
      userId,
      this.state.itemselectedMom,
      this.state.recordInfoMom,
      this
    );

    this.setState({ showmom: false });
  }

  handleSelectedItemsDad() {
    if (this.state.itemselectedDad.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedDad.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }
    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      dadText,
      userId,
      this.state.itemselectedDad,
      this.state.recordInfoDad,
      this
    );

    this.setState({ recordInfoDad: this.state.itemselectedDad });
    this.setState({ showdad: false });
  }

  handleSelectedItemsPart() {
    if (this.state.itemselectedPartner.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedPartner.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }
    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      partnerText,
      userId,
      this.state.itemselectedPartner,
      this.state.recordInfoPartner,
      this
    );

    this.setState({
      recordInfoPartner: this.state.itemselectedPartner,
    });
    this.setState({ showpartner: false });
  }

  getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (extension === 'pdf') {
      return 'pdf';
    }
    return 'unknown';
  }

  showMeInfolist = () => {
    console.log("this.state.recordInfoMe", this.state.recordInfoMe);
    let recordinfome = [];
    if (this.state.recordInfoMe.length !== 0) {
      if (this.state.userType === "bn") {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.recordInfoMe.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory">
                    {item.label}
                  </div>
                  <div className="col-2"></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.recordInfoMe.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory pb-1 pt-1 ">
                    {item.label}
                  </div>
                  <div className="col-2 tableColumn-twohistory pb-1 pt-1 " >
                    <a href="#/remove/" onClick={this.toggleDeleteMeItem.bind(this, item.id)}>
                      <i className="fa fa-trash" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                    </a>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingMe === false) {
        recordinfome = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        recordinfome = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfome;
  };

  showMeAttachmentlist = () => {
    let recordinfome = [];

    if (this.state.attachmentMe.length > 0) {
      if (this.state.userType === "bn") {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentMe.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ? (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) : (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={apiUrl.apiUrl + '/' + item.file_url}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >

                      </div>

                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentMe.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ? (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) : (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={("/file-attachment-viewer/" + this.state.encryptedString + '?file=' + item.file_url)}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >
                        <a href="#/remove/" onClick={this.toggleDeleteMeItem.bind(this, item.id)}>
                          <i className="fa fa-remove" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                        </a>
                      </div>

                    </div>
                  </div>

                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingMe === false) {
        recordinfome = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        recordinfome = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfome;
  };



  showPartInfoList = () => {
    let recordinfopart = [];
    if (this.state.recordInfoPartner.length !== 0) {
      if (this.state.userType === "bn") {
        recordinfopart = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.recordInfoPartner.map((item_part, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory">
                    {item_part.label}
                  </div>
                  <div className="col-2"></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfopart = (
          <div>
            <div className="row m-auto">
              {this.state.recordInfoPartner.map((item_part, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory pb-2">
                    {item_part.label}
                  </div>
                  <div className="col-2 tableColumn-twohistory">
                    <a href="#/remove/" onClick={this.toggleDeletePartnerItem.bind(this, item_part.id)}>
                      <i className="fa fa-trash" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                    </a>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingPart === false) {
        recordinfopart = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        recordinfopart = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfopart;
  };

  showPartnerAttachmentlist = () => {
    let recordinfome = [];

    if (this.state.attachmentPartner.length > 0) {
      if (this.state.userType === "bn") {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentPartner.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ? (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) : (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={apiUrl.apiUrl + '/' + item.file_url}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >

                      </div>

                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentPartner.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ? (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) : (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={apiUrl.apiUrl + '/' + item.file_url}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >
                        <a href="#/remove/" onClick={this.toggleDeleteMeItem.bind(this, item.id)}>
                          <i className="fa fa-remove" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                        </a>
                      </div>

                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingPartner === false) {
        recordinfome = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        recordinfome = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfome;
  };


  showMomInfoList = () => {
    let recordinfomom = [];
    if (this.state.recordInfoMom.length !== 0) {
      if (this.state.userType === "bn") {
        recordinfomom = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.recordInfoMom.map((item_mom, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory">
                    {item_mom.label}
                  </div>
                  <div className="col-2"></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfomom = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.recordInfoMom.map((item_mom, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory pb-2">
                    {item_mom.label}
                  </div>
                  <div className="col-2 tableColumn-twohistory">
                    <a
                      href="#/remove/"
                      onClick={this.toggleDeleteMomItem.bind(this, item_mom.id)}
                    >
                      <i className="fa fa-trash" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                    </a>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingMom === false) {
        recordinfomom = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        // recordinfomom = <p className="loading-msg-medic">No Medical Data</p>;
        recordinfomom = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfomom;
  };

  showMomAttachmentlist = () => {
    let recordinfome = [];

    if (this.state.attachmentMom.length > 0) {
      if (this.state.userType === "bn") {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentMom.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ? (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) : (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={apiUrl.apiUrl + '/' + item.file_url}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >

                      </div>

                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentMom.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ? (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) : (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={apiUrl.apiUrl + '/' + item.file_url}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >
                        <a href="#/remove/" onClick={this.toggleDeleteMeItem.bind(this, item.id)}>
                          <i className="fa fa-remove" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                        </a>
                      </div>

                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingMom === false) {
        recordinfome = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        recordinfome = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfome;
  };



  showDadInfoList = () => {
    let recordinfodad = [];
    if (this.state.recordInfoDad.length !== 0) {
      if (this.state.userType === "bn") {
        recordinfodad = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.recordInfoDad.map((item_dad, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory">
                    {item_dad.label}
                  </div>
                  <div className="col-2"></div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfodad = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.recordInfoDad.map((item_dad, i) => (
                <React.Fragment key={i}>
                  <div className="col-10 tableColumn-onehistory pb-2">
                    {item_dad.label}
                  </div>
                  <div className="col-2 tableColumn-twohistory">
                    <a
                      href="#/remove/"
                      onClick={this.toggleDeleteDadItem.bind(this, item_dad.id)}
                    >
                      <i className="fa fa-trash" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                    </a>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingDad === false) {
        recordinfodad = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        // recordinfodad = <p className="loading-msg-medic">No Medical Data</p>;
        recordinfodad = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfodad;
  };

  showDadAttachmentlist = () => {
    let recordinfome = [];

    if (this.state.attachmentDad.length > 0) {
      if (this.state.userType === "bn") {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentDad.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ? (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) : (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={apiUrl.apiUrl + '/' + item.file_url}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >

                      </div>

                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        recordinfome = (
          <div>
            <div className="row m-auto pl-1">
              {this.state.attachmentDad.map((item, i) => (
                <React.Fragment key={i}>
                  <div className="col-12 tableColumn-onehistory">
                    <div className="row border">
                      <div className="col-md-2 ">
                        {this.getFileType(item.file_url) == "image" ?
                          (<img src={imgIcon} style={{ height: '40px', width: '35px' }} />) :
                          (<img src={pdfIcon} style={{ height: '40px', width: '30px' }} />)}
                      </div>
                      <div className="col-md-8" style={{ paddingTop: '20px' }}>
                        <div ><a href={apiUrl.apiUrl + '/' + item.file_url}>{item.file_title}</a></div>
                        <div className="small">27.5 mb</div>
                      </div>
                      <div className="col-2 d-flex align-items-center" >
                        <a href="#/remove/" onClick={this.toggleDeleteMeItem.bind(this, item.id)}>
                          <i className="fa fa-remove" style={{ color: "#7e7e7e" }} aria-hidden="true"></i>
                        </a>
                      </div>

                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoadingDad === false) {
        recordinfome = <p className="loading-msg-medic">Loading Data...</p>;
      } else {
        recordinfome = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
          </div>
        );
      }
    }

    return recordinfome;
  };


  deleteMeItems() {
    const tempMeID = this.state.deleteID;
    let successDel = medicalService.deleteMedicalData(tempMeID);
    if (successDel) {
      const recordInfoMe = this.state.recordInfoMe.filter(
        (recordItem) => recordItem.id !== tempMeID
      );
      this.setState({ recordInfoMe });
      this.setState({ itemselectedMe: recordInfoMe });

      this.setState({ deleteMe: false });
    } else {
      window.alert("Delete failed");
    }
  }

  deleteMomItems() {
    const tempMomID = this.state.deleteID;

    let successDel = medicalService.deleteMedicalData(tempMomID);
    if (successDel) {
      const recordInfoMom = this.state.recordInfoMom.filter(
        (recordItem) => recordItem.id !== tempMomID
      );

      this.setState({ recordInfoMom });
      this.setState({ itemselectedMom: recordInfoMom });
      this.setState({ deleteMom: false });
    } else {
      window.alert("Delete failed");
    }


  }

  deleteDadItems() {
    const tempDadID = this.state.deleteID;


    let successDel = medicalService.deleteMedicalData(tempDadID);
    if (successDel) {
      const recordInfoDad = this.state.recordInfoDad.filter(
        (recordItem) => recordItem.id !== tempDadID
      );
      this.setState({ recordInfoDad });
      this.setState({ itemselectedDad: recordInfoDad });

      this.setState({ deleteDad: false });
    } else {
      window.alert("Delete failed");
    }

  }

  deletePartnerItems() {
    const tempPartID = this.state.deleteID;


    let successDel = medicalService.deleteMedicalData(tempPartID);
    if (successDel) {
      const recordInfoPartner = this.state.recordInfoPartner.filter(
        (recordItem) => recordItem.id !== tempPartID
      );

      this.setState({ recordInfoPartner });
      this.setState({ itemselectedPartner: recordInfoPartner });

      this.setState({ deletePartner: false });
    } else {
      window.alert("Delete failed");
    }
  }

  disableBeneficiaryLink = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Link className="link-style-beneficiaries" to="/beneficiaries">
          Beneficiaries
        </Link>
      );
    }
  };

  onAttachedFileMe = (e) => {
    let selectedAttachedFileMe = e.target.files[0];
    if (selectedAttachedFileMe) {
      this.setState({
        selectedAttachedFileMe: selectedAttachedFileMe,
      });
    }
  }

  render() {
    const { item, item_mom, item_dad, item_part } = this.state;

    return (
      <div id="main-wrapper" className="" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-header-position="fixed" data-boxed-layout="full" >
        <Helmet>

          <title>ThisHeart-MedicalHistory</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>

          <div className="container-fluid px-4">
            <Row className="my-4">
              <Col sm="8" className=" ">
                <div className="enable-2fa-header">
                  <h1 className="account-header mr-1">Medical History</h1>
                  {/* <img className="d-flex align-items-center sm-hide" src={lineOfColours} alt="line of colours" /> */}
                </div>
                <p style={{ fontSize: "15px" }}>
                  Your medical history is important information to leave behind.
                  Leave your medical history and the history of your family
                  here.
                </p>
              </Col>
            </Row>
          </div>
          <div className="container-fluid px-4">
            <div className="row">
              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
                <div className="card-container border p-2">
                  <div className="card-title card-bg-me">ME</div>
                  <div className="card-body pb-3">
                    {this.showAddNewBtnMe()}
                    <Modal
                      isOpen={this.state.showme}
                      toggle={this.ShowMeItem}
                      className={this.props.className + ' modal-lg'}
                    >
                      <ModalHeader toggle={this.ShowMeItem}>
                        My Medical History
                      </ModalHeader>

                      <ModalBody className="scroll-effect" style={{overflowX:'auto'}}>
                        <Row>
                          <Col md={3}>
                            <img src={zeroState} className="img-fluid"/>
                          </Col>
                          <Col md={8}>
                            <p className="mr-4">The patient's medical history includes a previous diagnosis of hypertension, managed with medication, along with a history of mild asthma, which has been stable for several years.</p>
                          </Col>
                        </Row>
                        <Form>
                          <Row>
                            <Col className="col-md-12 pt-3">
                              <Select
                                value={this.state.itemselectedMe}
                                onChange={this.handleItemChangeMe}
                                options={options}
                                isMulti
                                isSearchable
                                placeholder="Select Medical Condition"
                                required
                              />
                            </Col>
                          </Row>
                        </Form>
                        <style>
                          {`
                            input[type="file"] {
                              position: relative;
                              width: 100%;
                              height: calc(3.0em + .75rem + 2px);
                              border: 1px solid #ccc;
                              border-radius: 4px;
                              display: inline-block;
                              padding: 11px;
                              margin: 0;
                              font-size: 1rem;
                              cursor: pointer;
                            }

                            input[type="file"]::file-selector-button {
                              padding: 0.5em 0.8em;
                              border-radius: 0.2em;
                              background-color: #f9f9f9;
                              transition: background-color 1s;
                              border: none;
                              text-align: center;
                              font-size: 1rem;
                              cursor: pointer;
                            }
                          `}
                        </style>
                        <Row className="d-none">
                          <Col>
                            <div><Label>Attach documents</Label></div>
                            <input
                              type="file"
                              onChange={this.onAttachedFileMe}
                              accept="image/*,application/pdf"
                              className="form-control"
                              id="imgAttachMe"
                              name="imgAttachMe"
                            />
                            {/* <input type="file" onChange={this.onAttachedFileMe} accept="image/*,application/pdf" className="form-control" id="imgAttachMe" name="imgAttachMe" style={{height: "calc(2.0em + .75rem + 2px)"}} /> */}
                          </Col>
                        </Row>

                        <Button
                          onClick={this.handleSelectedItemsMe}
                          className="mt-3"
                          style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                        >
                          Save Items
                        </Button>
                      </ModalBody>
                    </Modal>

                    <div className="card-info">{this.showMeInfolist()}</div>
                    {/* <div className="card-info"></div> */}
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
                <div className="card-container border p-2">
                  <div className="card-title card-bg-partner">PARTNER</div>
                  <div className="card-body">
                    {this.showAddNewBtnPartner()}
                    <Modal
                      isOpen={this.state.showpartner}
                      toggle={this.ShowPartnerItem}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.ShowPartnerItem}>
                        Partner Medical History
                      </ModalHeader>

                      <ModalBody className="scroll-effect" style={{overflowX:'auto'}}>

                      <Row>
                          <Col md={3}>
                            <img src={zeroState} className="img-fluid"/>
                          </Col>
                          <Col md={8}>
                            <p className="mr-4">The patient's medical history includes a previous diagnosis of hypertension, managed with medication, along with a history of mild asthma, which has been stable for several years.</p>
                          </Col>
                        </Row>

                        <Select
                          value={this.state.itemselectedPartner}
                          onChange={this.handleItemChangePart}
                          options={options}
                          isMulti
                          isSearchable
                          placeholder="Select Medical Condition"
                          required
                        />

                        <Button
                          onClick={this.handleSelectedItemsPart}
                          disabled={this.state.partSaveItemBtn === false}
                          style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                        >
                          Save Items
                        </Button>
                      </ModalBody>
                    </Modal>
                    <div className="card-info">
                      <div>{this.showPartInfoList()}</div>
                      <div className="card-info"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
                <div className="card-container border p-2">
                  <div className="card-title card-bg-mom">MOM</div>
                  <div className="card-body">
                    {this.showAddNewBtnMom()}
                    <Modal
                      isOpen={this.state.showmom}
                      toggle={this.ShowMomItem}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.ShowMomItem}>
                        Mom Medical History
                      </ModalHeader>

                      <ModalBody className="scroll-effect" style={{overflowX:'auto'}}>
                        <Row>
                          <Col md={3}>
                            <img src={zeroState} className="img-fluid"/>
                          </Col>
                          <Col md={8}>
                            <p className="mr-4">The patient's medical history includes a previous diagnosis of hypertension, managed with medication, along with a history of mild asthma, which has been stable for several years.</p>
                          </Col>
                        </Row>

                        <Select
                          value={this.state.itemselectedMom}
                          onChange={this.handleItemChangeMom}
                          options={options}
                          isMulti
                          isSearchable
                          placeholder="Select Medical Condition"
                          required
                        />

                        <Button
                          onClick={this.handleSelectedItemsMom}
                          disabled={this.state.momSaveItemBtn === false}
                          style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                        >
                          Save Items
                        </Button>
                      </ModalBody>
                    </Modal>

                    <div className="card-info">
                      <div>{this.showMomInfoList()}</div>
                      <div className="card-info"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 mb-1">
                <div className="card-container border p-2">
                  <div className="card-title card-bg-dad">DAD</div>
                  <div className="card-body">
                    {this.showAddNewBtnDad()}
                    <Modal
                      isOpen={this.state.showdad}
                      toggle={this.ShowDadItem}
                      className={this.props.className}
                    >
                      <ModalHeader toggle={this.ShowDadItem}>
                        Dad Medical History
                      </ModalHeader>

                      <ModalBody className="scroll-effect" style={{overflowX:'auto'}}>
                        <Row>
                          <Col md={3}>
                            <img src={zeroState} className="img-fluid"/>
                          </Col>
                          <Col md={8}>
                            <p className="mr-4">The patient's medical history includes a previous diagnosis of hypertension, managed with medication, along with a history of mild asthma, which has been stable for several years.</p>
                          </Col>
                        </Row>
                        <Select
                          value={this.state.itemselectedDad}
                          onChange={this.handleItemChangeDad}
                          options={options}
                          isMulti
                          isSearchable
                          placeholder="Select Medical Condition"
                          required
                        />

                        <Button
                          onClick={this.handleSelectedItemsDad}
                          disabled={this.state.dadSaveItemBtn === false}
                          style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                        >
                          Save Items
                        </Button>
                      </ModalBody>
                    </Modal>

                    <div className="card-info">
                      <div>{this.showDadInfoList()}</div>
                      <div className="card-info"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /****************************** Delete Me Items Custom Pop Up Start ****************************/}

        <div>
          <Modal
            isOpen={this.state.deleteMe}
            toggle={this.toggleDeleteMeItem}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteMeItem}>
              My History
            </ModalHeader>

            <ModalBody className="medical-popup">
              <p>Are you sure, want to Delete this item?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteMeItems.bind(this, item)}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteMeItem}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal
            isOpen={this.state.otherMeItem}
            toggle={this.toggleOtherItemMe}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOtherItemMe}>
              My History Other
            </ModalHeader>

            <ModalBody className="medical-popup">
              <Input id="otherItem" placeholder="Enter Diagonsis:"></Input>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.otherItemSaveMe}
                style={{ borderRadius: "7px" }}
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={this.toggleOtherItemMe}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal
            isOpen={this.state.otherPartnerItem}
            toggle={this.toggleOtherItemPartner}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOtherItemPartner}>
            Input Your Partner Medical History
            </ModalHeader>

            <ModalBody className="medical-popup">
              <Input id="otherItemPart" placeholder="Enter Diagonsis:"></Input>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.otherItemSavePartner}
                style={{ borderRadius: "7px" }}
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={this.toggleOtherItemPartner}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal
            isOpen={this.state.otherItemMom}
            toggle={this.toggleOtherItemMom}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOtherItemMom}>
             Input Your Mom Medical History
            </ModalHeader>

            <ModalBody className="medical-popup">
              <Input id="otherItemMom" placeholder="Enter Diagonsis:"></Input>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.otherItemSaveMom}
                style={{ borderRadius: "7px" }}
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={this.toggleOtherItemMom}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        <div>
          <Modal
            isOpen={this.state.otherItemDad}
            toggle={this.toggleOtherItemDad}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleOtherItemDad}>
            Input Your Dad Medical History
            </ModalHeader>

            <ModalBody className="medical-popup">
              <Input id="otherItemDad" placeholder="Enter Diagonsis:"></Input>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.otherItemSaveDad}
                style={{ borderRadius: "7px" }}
              >
                Save
              </Button>
              <Button
                type="button"
                onClick={this.toggleOtherItemDad}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* /****************************** Delete Me item Custom Pop Up End ****************************/}

        {/* /****************************** Delete Mom Items Custom Pop Up Start ****************************/}

        <div>
          <Modal
            isOpen={this.state.deleteMom}
            toggle={this.toggleDeleteMomItem}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteMomItem}>
              Mom History
            </ModalHeader>

            <ModalBody className="medical-popup">
              <p>Are you sure, want to Delete this item?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteMomItems.bind(this, item_mom)}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteMomItem}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* /****************************** Delete Mom Items Custom Pop Up End ****************************/}

        {/* /****************************** Delete Dad Items Custom Pop Up Start ****************************/}

        <div>
          <Modal
            isOpen={this.state.deleteDad}
            toggle={this.toggleDeleteDadItem}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteDadItem}>
              Dad History
            </ModalHeader>

            <ModalBody className="medical-popup">
              <p>Are you sure, want to Delete this item?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteDadItems.bind(this, item_dad)}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteDadItem}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* /****************************** Delete Dad Items Custom Pop Up End ****************************/}

        {/******************************* Delete Partner Items Custom Pop Up Start ****************************/}

        <div>
          <Modal
            isOpen={this.state.deletePartner}
            toggle={this.toggleDeletePartnerItem}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeletePartnerItem}>
              Partner History
            </ModalHeader>

            <ModalBody className="medical-popup">
              <p>Are you sure, want to Delete this item?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deletePartnerItems.bind(this, item_part)}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeletePartnerItem}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/******************************* Delete Partner Items Custom Pop Up End ****************************/}

        {/* *********************** Profile Image Pop Up Window Start ********************************* */}

        {/* *********************** Profile Image Pop Up Window End ********************************* */}
      </div>
    );
  }
}

export default MedicalHistory;
