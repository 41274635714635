import React, { Component } from "react";
import { FaMoneyCheck } from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import "../components/asterisk.css";
import "./styles/PackageSubscription.css";
import { Helmet } from "react-helmet";

import Header from "./../components/Header";
import apiService from "./../services/api.service";
import { authHeader } from "../helpers/auth-header";

import { Col, Row, FormGroup, Input, Button, Label, CustomInput } from "reactstrap";
import "./styles/accounts.css";

import { encrypt_url } from "./../helpers/encrypt-url";
import PackagesServices from "./../services/packages.service";
import { ToastsStore } from "react-toasts";
import axios from "axios";
import { loadStripe } from "@stripe/stripe-js";
// import MainServices from "../services/main.service";

const apiUrl = apiService.apiUrl;
class PackageSubscriptionProfile extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");


    this.state = {
      userObj: JSON.parse(user),
      userPackage: "",
      allPackages: "",
      selectPackageView: true,
      selectedPackage: "",
      encryptedString: encrypt_url(),
      paymentSession: "",
      package_info: "",
      paymentType: 'monthly',
      billingType: true,
    };
  }

  componentDidMount() {
    this.getPackageByUser();
  }

  componentWillUnmount() {
    // this.mounted = false;
  }

  pkgSelected = (pkg) => {
    // let pkg = JSON.parse(e.target.value);
    this.setState({
      selectedPackage: {
        pkgID: pkg.id,
        pkgName: pkg.package,
        pkgPrice: pkg.price,
        pkgYearPrice: pkg.year_price,
        pkgDescription: pkg.description,
        pkgStripeMonthPricePlan: pkg.stripe_month_price_plan,
        pkgStripeYearPricePlan: pkg.stripe_year_price_plan,
        paymentType: this.state.paymentType,
        billingType: this.state.billingType
      },
    });
  };

  paymentTypeSelected = (e) => {
    let paymentType = e.target.value;
    this.setState({
      paymentType: paymentType,
      selectedPackage: {
        ...this.state.selectedPackage,
        paymentType: paymentType,
      },
    });
  };
  billingTypeSelected = (e) => {
    let billingType = e.target.checked;
    console.log('recurring type:', billingType)
    this.setState({
      billingType: billingType,
      selectedPackage: {
        ...this.state.selectedPackage,
        billingType: billingType
      },
    });
  };

  showPackageList = () => {
    console.log('allPackages', this.state.allPackages);
    return this.state.allPackages &&
      this.state.allPackages.map((pkg, inx) => {
        const isSelected = this.state.selectedPackage.pkgName === pkg.package;
        let packageDetails = [];
        switch (pkg.package) {
          case "STARTER":
            packageDetails = [
              "10Gb of data storage",
              "Store 10 accounts",
              "Access to 2 beneficiaries",
              "Email user support"
            ];
            break;
          case "ACCESS":
            packageDetails = [
              "Unlimited data storage",
              "Unlimited account storage",
              "Unlimited beneficiary access",
              "Email and chat support"
            ];
            break;
          case "FAMILY":
            packageDetails = [
              "All of the access plan features",
              "For up to 3 of your family members",
              "That's 4 total accounts including yours"
            ];
            break;
          default:
            packageDetails = [];
        }
        return (
          <div className="text-black" key={inx}>
            <FormGroup style={{ borderRadius: '7px' }}>
              <Row>
                <Col sm={8}>
                  <Label
                    id={inx}
                    style={{ marginBottom: "0px" }}
                  >
                    <Input
                      type="radio"
                      id={inx}
                      name="selectedPackage"
                      value={JSON.stringify(pkg)}
                      checked={isSelected}
                      onChange={() => this.pkgSelected(pkg)}
                      style={{ backgroundColor: isSelected ? '#f6db20' : 'transparent' }}
                    />
                    <b className="pl-1">{pkg.package}</b>
                  </Label>
                </Col>
                <Col sm={4} className="text-center" style={{ alignSelf: "center" }}>
                  <p style={{ fontWeight: '400' }}>${this.state.paymentType === "monthly" ? pkg.price : pkg.year_price}/{this.state.paymentType}</p>
                </Col>
              </Row>
              {isSelected && (
                <div className="package-details mb-2 p-2" style={{backgroundColor: '#f6db20', borderRadius: '7px', fontWeight: '400'}}>
                  <Row>
                    <Col sm={6}>
                      <ul>
                        {packageDetails.slice(0, 2).map((detail, index) => (
                          <li key={index}>{detail}</li>
                        ))}
                      </ul>
                    </Col>
                    {packageDetails.length > 2 && (
                      <Col sm={6}>
                        <ul>
                          {packageDetails.slice(2).map((detail, index) => (
                            <li key={index}>{detail}</li>
                          ))}
                        </ul>
                      </Col>
                    )}
                  </Row>
                </div>
              )}
            </FormGroup>
          </div>
        );
      });

  }

  handleStripePayment = () => {
    const stripes = loadStripe("pk_test_bgDg00SDeZ5X4jctyhmpeNqV00SVlg42Ll");
    //var stripe = loadStripe('sk_test_9DkPWEVGZrgEo6q9EeZBDXlC00rgoKMYML');

    let header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    };

    if (
      !this.state.selectedPackage.pkgID ||
      !this.state.selectedPackage.pkgPrice
    ) {
      ToastsStore.error("Please select a package for subscription!");
      return false;
    }

    let payload = {
      item_id: this.state.selectedPackage.pkgID,
      item: this.state.selectedPackage.pkgName,
      description: this.state.selectedPackage.pkgDescription,
      amount: this.state.selectedPackage.pkgPrice,
      year_amount: this.state.selectedPackage.pkgYearPrice,
      stripe_month_price_plan: this.state.selectedPackage.pkgStripeMonthPricePlan,
      stripe_year_price_plan: this.state.selectedPackage.pkgStripeYearPricePlan,
      payment_type: this.state.paymentType,
      billing_type: this.state.billingType,
      encryptedString: this.state.encryptedString,
    };

    axios
      .post(`${apiUrl}/api/package/payment/create-session-profile`, payload, header)
      .then(
        (response) => {
          if (response.data.status === "success") {
            this.setState({
              paymentSession: response.data.session,
            });
            stripes.then(
              (result) => {
                ///this.props.history.push(`/demopaymentpage/${this.state.paymentSession.id}`)
                result
                  .redirectToCheckout({
                    sessionId: this.state.paymentSession.id,
                  })
                  .then(function (result) {
                    // console.log("payment details*************: ", result);
                  });
              },
              (error) => {
                console.log(error.response);
                ToastsStore.error(error.response.data.message);
              }
            );
          }
        },
        (error) => {
          console.log(error.response);
          ToastsStore.error(error.response.data.message);
        }
      );
  };


  bodyPackageContent = () => {
    if (this.state.selectPackageView) {
      return (
        <div>
          <div className="">
            <h2>Subscription</h2>
          </div>
          <div className="card" style={{ border: '10px solid #e6e6e6', borderRadius: '10px' }}>
            <div className="card-header bg-thisheart" style={{ borderRadius: '0px 0px 10px 10px', backgroundColor: '#000', fontWeight: '600', color: '#fff' }}>Package & Payment</div>
            <Row className="m-0 container" style={{ borderRadius: '10px' }}>
              <Col sm={12}>
                <div className="card" style={{ border: '0px' }}>
                  <div className="card-body">
                    <Row className="mt-3 mx-0">
                      <Col sm={7}>
                        <FormGroup className="mt-3">
                          <p htmlFor="exampleCheckbox ul-package text-black" style={{fontSize: '1rem'}}>Payment Type</p>
                          <div className="card" style={{ border: '0px', backgroundColor: '#f6db20', borderRadius: '7px' }}>
                            <Row className="m-0">
                              <Col sm={6}>
                                <CustomInput
                                  type="radio"
                                  id="paymentType1"
                                  name="paymentType"
                                  label="Monthly"
                                  value="monthly"
                                  className="m-3 text-black ul-package font-weight-bold"
                                  defaultChecked={this.state.paymentType === "monthly"}
                                  onClick={this.paymentTypeSelected}
                                />
                              </Col>
                              <Col sm={6}>
                                <CustomInput
                                  type="radio"
                                  id="paymentType2"
                                  name="paymentType"
                                  label="Yearly"
                                  value="yearly"
                                  className="m-3 text-black ul-package font-weight-bold"
                                  defaultChecked={this.state.paymentType === "yearly"}
                                  onClick={this.paymentTypeSelected}
                                />
                              </Col>
                            </Row>
                          </div>
                        </FormGroup>

                        <br />
                        <p className="text-black" style={{fontSize: '1rem'}}>Package</p>
                        <hr className="text-black" />
                        <ul className="mt-1 pl-4">
                          {this.showPackageList()}
                        </ul>

                      </Col>

                      <Col sm={5} className="text-center">
                        <img src="/images/package-payment.png" className="mt-4" width="100%" height="auto" alt="Payment" />
                        <h4 className="mt-4">Selected Package:</h4>
                        <h5 className="text-info">
                          {this.state.selectedPackage.pkgName}/
                          {this.state.paymentType}
                        </h5>
                        <h3>${this.state.paymentType === "monthly" ? this.state.selectedPackage.pkgPrice : this.state.selectedPackage.pkgYearPrice}</h3>

                        <Button
                          className="btn bg-thisheart"
                          onClick={this.handleStripePayment}
                        >
                          <FaMoneyCheck /> &nbsp; Make Payment
                        </Button>

                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
              <Col sm={5}></Col>
            </Row>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Row className="mr-3">
          </Row>
        </div>
      );
    }
  };

  selectPackage = () => {
    this.setState({ selectPackageView: true });
  };

  getPackages = () => {
    PackagesServices.getPackages(this).then(
      (response) => {
        // console.log(response)
        if (response.data.status === "success") {
          this.setState({
            packageList: response.data.data,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getPackageByUser = () => {
    if (localStorage.getItem('user_type') === "bn") {
      return false;
    }
    PackagesServices.getPackageByUser(this).then(
      (response) => {
        console.log("response", response);
        if (response.data.status === "success") {
          let allPackage = response.data.all_package_list;
          // allPackage.shift();
          let pkgList = allPackage.filter((pkg) => {
            if (
              pkg.package.toLowerCase() !== "admin" &&
              pkg.package.toLowerCase() !== "trial package" &&
              pkg.package.toLowerCase() !== "free account"
            ) {
              return true;
            }
          });

          this.setState({
            userPackage: response.data.data,
            allPackages: pkgList,
            selectedPackage: {
              pkgID: response.data.data.id,
              pkgName: response.data.data.package,
              pkgPrice: response.data.data.price,
              pkgYearPrice: response.data.data.year_price,
              pkgDescription: response.data.data.description,
              pkgStripeMonthPricePlan: response.data.data.stripe_month_price_plan,
              pkgStripeYearPricePlan: response.data.data.stripe_year_price_plan,
            },
          });
        }
      },
      (error) => {
        console.log(error);
        ToastsStore.error(
          "Sorry, package info error, "
        );
      }
    );
  };

  render() {
    return (
      <div className="container-fluid packagesubscriptionprofile">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Package Payment</title>
        </Helmet>
        <Header {...this.props} />

        <div className="container">
          <Row>
            <Col sm={12} className="offset-md-2 ml-0">{this.bodyPackageContent()}</Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PackageSubscriptionProfile;
