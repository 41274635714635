import React, { Component, useRef } from "react";
import Header from "../components/Header";
import { ToastsStore } from "react-toasts";
import "./styles/editprofile.css";
import "../components/asterisk.css";
import { Helmet } from "react-helmet";
import loaderImg from "./images/loader.gif";
import package_icon from "./images/starter.svg";
import subscription_icon from "./images/subscription.svg";
import unsubscription_icon from "./images/unsubscribe.svg";
import arrowUpRight from "./images/arrow-up-right.svg";
import arrowUpRightBK from "./images/arrow-up-right-bk.svg";
import camera from "./images/camera.svg";
import { FaEyeSlash, FaEye, FaCaretDown, FaUserTimes, FaUserCheck } from "react-icons/fa";
import PackagesServices from "./../services/packages.service";
import removeProfilePhoto from "../pages/images/delete-user.svg";
// import { PhoneInput } from 'react-international-phone';
// import 'react-international-phone/style.css';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import 'flag-icons/css/flag-icons.min.css';
import LeftSidebar from "./../components/LeftSidebar";
import { Form, FormGroup, Input, Label, Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, CustomInput, Media } from "reactstrap";
import { userService } from "../services/user.service";
import { encrypt_url } from "../helpers/encrypt-url";
 
class UpdateStatus extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    let userObj = JSON.parse(user);
    this.fileInputRef = React.createRef();
    this.fileUploadRef = React.createRef();
    this.state = {
      userObj: userObj,
      showPlan: false,
      showPayment: false,
      loading: false,
      userName: "",
      email: "",
      additional_email: "",
      currentPass: "",
      newPass: "",
      confirmPass: "",
      passwordScore: "",
      errorPassword: [],
      subPlan: 0,
      selectPackage: 0,
      passValue: "password",
      newPassValue: "password",
      errors: [],
      btnActive: false,
      status_message: {
        user_id: userObj.id,
        your_memories: "",
        your_accounts: "",
        medical_history: "",
        accounts:"",
        audio_memories:"",
        video_memories:""
      },
      editId: 0,
      userType: localStorage.getItem("user_type"),
      packageList: [],
      pay_method: "",
      card_number: "",
      expire_date: "",
      security_code: "",
      profile_image: "",
      selectProfileImage: "",
      loaderProfileImage: false,
      package_info: "",
      encryptedString: encrypt_url(),
      updateProfileSpin: false,
      modalUnsubscription: false,
      user_package_status: "",
      editUserInfo: false,
  
    };
  }

  componentDidMount() {
 
    userService.getUserStatusMessages(this.state.userObj.data.user_id, this)
 
  }

  
  showPlanOption = () => {
    this.setState((prevState) => ({
      showPlan: !prevState.showPlan,
    }));
  };


  handleFormSubmit = (e) => {
    e.preventDefault();
    this.setState({ loadingPkg: true });
    let data = {
      sub_plan: this.state.userList.subPlan,
    };
    PackagesServices.savePackageInfo(this, data).then(
      (response) => {
        if (response.data.status === "success") {
          let userTmp = this.state.userObj;
          userTmp.data.sub_plan = response.data.sub_plan;
          this.setState({
            userObj: userTmp,
          });

          localStorage.setItem("user", JSON.stringify(this.state.userObj));
          if (response.data.package_info.price === 0) {
            this.setState({ loadingPkg: false });
          } else {
            this.setState((prevState) => ({
              showPayment: !prevState.showPayment,
              loadingPkg: false,
              showPlan: false,
            }));
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };


  changePackage = () => {
    this.props.history.push(
      `/packagesubscriptionprofile/${this.state.encryptedString}`
    );
  };
 
  


  editUserInfo = () => {
    this.setState({ editUserInfo: true })
  }

  saveEditUserInfo = () => {
    this.setState({ editUserInfo: false })
    userService.editUserInfo(this, this.state.strUserInfo);
  }

  changeEditUserInfo = (e) => {
    this.setState({ strUserInfo: e.target.value })
  }

  showProfileList = () => {
    let itemList = [];
    itemList = (
      <div>
       
        <Form method="post" onSubmit={this.updateProfile}>
 
          <div className="row mb-4">
            <div className="col-md-8">
              <div className="lbl-prof">Your Memories</div>
              <Input
                type="text"
                name="your_memories"
                id="your_memories"
                required
                value={this.state.status_message.your_memories}
                onChange={this.onChangeYourMemories}
                className="form-control"
              />
              <div className="errormessage">{this.state.errors.your_memories}</div>
            </div>
 
          </div> 


          <div className="row mb-4">
            <div className="col-md-8">
              <div className="lbl-prof">Your Accounts</div>
              <Input
                type="text"
                name="your_accounts"
                id="your_accounts"
                required
                value={this.state.status_message.your_accounts}
                onChange={this.onChangeYourMemories}
                className="form-control"
              />
              <div className="errormessage">{this.state.errors.your_accounts}</div>
            </div>
 
          </div>

          <div className="row mb-4">
            <div className="col-md-8">
              <div className="lbl-prof">Medical History</div>
              <Input
                type="text"
                name="medical_history"
                id="medical_history"
                required
                value={this.state.status_message.medical_history}
                onChange={this.onChangeYourMemories}
                className="form-control"
              />
              <div className="errormessage">{this.state.errors.medical_history}</div>
            </div>
 
          </div>
 

          <div className="row mb-4">
            <div className="col-md-8">
              <div className="lbl-prof">Accounts</div>
              <Input
                type="text"
                name="accounts"
                id="accounts"
                required
                value={this.state.status_message.accounts}
                onChange={this.onChangeYourMemories}
                className="form-control"
              />
              <div className="errormessage">{this.state.errors.accounts}</div>
            </div>
 
          </div>

          <div className="row mb-4">
            <div className="col-md-8">
              <div className="lbl-prof">Audio Memories</div>
              <Input
                type="text"
                name="audio_memories"
                id="audio_memories"
                required
                value={this.state.status_message.audio_memories}
                onChange={this.onChangeYourMemories}
                className="form-control"
              />
              <div className="errormessage">{this.state.errors.audio_memories}</div>
            </div>
 
          </div>

          <div className="row mb-4">
            <div className="col-md-8">
              <div className="lbl-prof">Video Memories</div>
              <Input
                type="text"
                name="video_memories"
                id="video_memories"
                required
                value={this.state.status_message.video_memories}
                onChange={this.onChangeYourMemories}
                className="form-control"
              />
              <div className="errormessage">{this.state.errors.video_memories}</div>
            </div>
 
          </div>
 
          <hr className="mt-4" />
          <Row>
            <Col lg={6}></Col>
            <Col lg={8} sm={8}>
              <div className="btn-updt text-right">
                <p className="help-profile">{this.state.errorMessage}</p>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}
                <Button
                  type="submit"
                  className="btn bg-thisheart"
                  disabled={this.state.btnActive === false}
                >
                  {this.state.updateProfileSpin && (
                    <i className="fa fa-circle-o-notch fa-spin"></i>
                  )}
                  &nbsp; Update Status
                  {this.state.btnActive === false ? (<img src={arrowUpRight} className="ml-2" />) : (<img src={arrowUpRightBK} className="ml-2" />)}
                </Button>
                 

              </div>
            </Col>
          </Row>
        </Form>
      </div>
    );
    return itemList;
  };

   
 
  onChangeYourMemories = (e) => {
    this.setState({
      status_message: {
        ...this.state.status_message,
        [e.target.name] : e.target.value,
      },
      btnActive: true,
    });

    console.log('value::- ', this.state. status_message)
  };


  updateProfile = (e) => {
    e.preventDefault();
    const status_message = this.state.status_message;
    const dataStatusMessage = {
      user_id: this.state.userObj.data.user_id,
      your_memories: status_message.your_memories,
      your_accounts: status_message.your_accounts,
      medical_history: status_message.medical_history,
      accounts: status_message.accounts,
      audio_memories: this.state.status_message.audio_memories,
      video_memories: this.state.status_message.video_memories,
    };
    userService.editStatusMessage(dataStatusMessage, this);
    this.setState({ btnActive: true, updateProfileSpin: false });
  };

  eyeIcon = (eyetype) => {
    switch (eyetype) {
      case "currentPassword":
        if (this.state.passValue === "password") {
          this.setState({ passValue: "text" });
        } else {
          this.setState({ passValue: "password" });
        }
        break;
      case "newPassword":
        if (this.state.newPassValue === "password") {
          this.setState({ newPassValue: "text" });
        } else {
          this.setState({ newPassValue: "password" });
        }
        break;

      default:
    }
  };

  render() {
    return (
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin1" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">

        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart- Update Account</title>
        </Helmet>

        <Header {...this.props} profile_image={this.state.profile_image} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container-fluid px-4">
            <div className="prof-head">
              <Row>
                <Col sm="12">
                  <div className="profile-name">
                    <h1>Update Status Message</h1>
                  </div>
                </Col>
              </Row>
            </div>

            <div>
              <Row>
                <Col md={8} sm={12} xs={12} className="tab-profile-div">
                  <div className="form-box">{this.showProfileList()}</div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/******************************* Subscription plan Start ****************************/}

        <div className="modal-div">
          <Modal
            isOpen={this.state.showPlan}
            toggle={this.showPlanOption}
            className={this.props.className}
          >
            <ModalHeader toggle={this.showPlanOption}>
              Subscription Plan
            </ModalHeader>
            <ModalBody className="form-popup">
              <Form method="post" onSubmit={this.handleFormSubmit}>
                <FormGroup>
                  <Label>
                    <b>Select your desire plan</b>
                  </Label>
                  <div>
                    {this.state.packageList &&
                      this.state.packageList.map((packages, index) => {
                        return (
                          <CustomInput
                            type="radio"
                            key={index}
                            id={packages.id}
                            name="customRadio"
                            label={packages.package}
                            value={packages.id + "=" + packages.package}
                            onChange={this.handleOptionChange}
                            defaultChecked={
                              packages.id === this.state.userList.subPlan
                            }
                            required
                          />
                        );
                      })}
                  </div>
                  {this.state.loadingPkg && (
                    <img
                      src={loaderImg}
                      alt="loading"
                      height="40px"
                      width="auto"
                    />
                  )}
                </FormGroup>

                <Button
                  className="option-btnSave "
                  type="submit"
                  onClick={this.showPlanOption}
                >
                  Save
                </Button>
                <Button
                  className="option-btnCancel"
                  type="button"
                  onClick={this.showPlanOption}
                >
                  Cancel
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
        {/* /****************************** Subscription plan option End ****************************/}

        {/******************************* Subscription payment method start Start ****************************/}

        <div className="modal-div">
          <Modal
            isOpen={this.state.showPayment}
            toggle={this.showPaymentMethod}
            className={this.props.className}
          >
            <ModalHeader toggle={this.showPaymentMethod}>
              Payment Method
            </ModalHeader>
            <ModalBody className="form-popup">
              <Form method="post" onSubmit={this.handleSubmitPayment}>
                <FormGroup>
                  <Label>
                    <b>Select Payment Method</b>
                  </Label>
                  <div>
                    <CustomInput
                      type="radio"
                      key="1"
                      id="p-1"
                      name="pay_method"
                      label="Master Card"
                      value="Master Card"
                      onChange={this.handlePaymentChange}
                      required
                    />

                    <CustomInput
                      type="radio"
                      key="2"
                      id="p-2"
                      name="pay_method"
                      label="Visa Card"
                      value="Visa Card"
                      onChange={this.handlePaymentChange}
                      required
                    />
                  </div>
                  {this.state.loadingPkg && (
                    <img
                      src={loaderImg}
                      alt="loading"
                      height="40px"
                      width="auto"
                    />
                  )}
                </FormGroup>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="lbl-prof">Card Number</Label>
                      <Input
                        type="number"
                        name="card_number"
                        id="card_number"
                        required
                        placeholder="Enter Card Number"
                        value={this.state.card_number}
                        onChange={this.handlePaymentChange}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="lbl-prof">Expire</Label>
                      <Input
                        type="month"
                        name="expire_date"
                        id="expire_date"
                        required
                        placeholder="Card Expiry Date"
                        value={this.state.expire_date}
                        onChange={this.handlePaymentChange}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <FormGroup>
                      <Label className="lbl-prof">Security Code</Label>
                      <Input
                        type="number"
                        name="security_code"
                        id="security_code"
                        required
                        placeholder="Security Code"
                        value={this.state.security_code}
                        onChange={this.handlePaymentChange}
                      />
                    </FormGroup>
                  </div>
                </div>

                <Button className="option-btnSave " type="submit">
                  Save
                </Button>
                <Button
                  className="option-btnCancel"
                  type="button"
                  onClick={this.showPaymentMethod}
                >
                  Cancel
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>
        {/* /****************************** Subscription payment method End ****************************/}

        {/* /****************************** Subscription Modal Start ****************************/}
        <div className="modal-div">
          <Modal
            isOpen={this.state.modalUnsubscription}
            toggle={this.toggleUnsubscription}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleUnsubscription}>
              {this.state.user_package_status === 0 ? <b>Subscribe</b> : <b>Unsubscribe</b>}
            </ModalHeader>
            <ModalBody className="logout-acc">
              <p>Are you sure you want to {this.state.user_package_status === 0 ? <b>Subscribe</b> : <b>Unsubscribe</b>} the current package finally?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" type="button" onClick={this.changePackageUnSubscribe}>
                {this.state.user_package_status === 0 ? <b>Subscribe</b> : <b>Unsubscribe</b>}
              </Button>
              <Button
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                type="button"
                onClick={this.toggleUnsubscription}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>

        {/* <div className="modal-div">
          <Modal
            isOpen={this.state.modalUnsubscription}
            toggle={this.toggleUnsubscription}
            className={this.props.className}
          >
            <ModalHeader toggle={() => this.toggleUnsubscription(this.state.isSubscribing)}>
              {(this.state.user_package_status == 0) ? (<b>Subscribe</b>) : (<b>Unsubscribe</b>)}
              
            </ModalHeader>
            <ModalBody className="logout-acc">
              <p>Are you sure you want to {(this.state.user_package_status == 0) ? (<b>Subscribe</b>) : (<b>Unsubscribe</b>)}   the current package finally?</p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" type="button" onClick={this.changePackageUnSubscribe}>
                {(this.state.user_package_status==0)?(<b>Subscribe</b>):(<b>Unsubscribe</b>)} 
              </Button>
              <Button style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }} type="button" onClick={this.toggleUnsubscription}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div> */}
        {/* /****************************** Subscription Modal End ****************************/}




      </div>
    );
  }
}
export default UpdateStatus;
